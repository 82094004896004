import inDOM from 'dom-helpers/canUseDOM'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import map from 'lodash/map'
import { span } from '../../../style/span'
import theme from '../../../style/theme'
import { vw } from '../../../style/vw'
import { ReactComponent as GripIcon, aspectRatio as gripAspect } from '../../../images/grip.svg'
import round from '../../../helpers/round'
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import useResizeObserver from '../../../hooks/useResizeObserver'
import useMouseHover from '../../../hooks/useMouseHover'
import dotAnimationData from '../../../animations/initiativesHover.json'
import useReversingLottieAnimation from '../../../hooks/useReversingLottieAnimation'
import Progress from './Progress'
import { useInView } from 'react-intersection-observer'

if (inDOM) {
  gsap.registerPlugin(Draggable)
}

function useKnob (steps, onDrag) {
  const ref = useRef()
  const draggableRef = useRef()
  const sizesRef = useRef({})

  const getStepIndex = x => {
    const { trackLength, stepLength } = sizesRef.current
    if (x < 0) {
      return 0
    }
    if (x > trackLength) {
      return steps - 1
    }
    return Math.min(x / stepLength, steps - 1)
  }

  const getNearestStepX = x => {
    const { stepLength } = sizesRef.current
    const step = getStepIndex(x)
    return Math.round(step) * stepLength
  }

  const seekToX = (x, { duration, ease = 'power3.out' }) => {
    const draggable = draggableRef.current
    if (!draggable) {
      return
    }
    gsap.to(draggable.target, {
      x,
      duration,
      ease,
      onUpdate () {
        draggable.update()
        onDrag(getStepIndex(draggable.x))
      }
    })
  }

  useResizeObserver(ref, useCallback(() => {
    const trackEl = ref.current
    const knobEl = trackEl.childNodes[0]
    const { width: knobSize } = knobEl.getBoundingClientRect()
    const { width: trackWidth } = trackEl.getBoundingClientRect()
    const trackLength = trackWidth - knobSize
    const stepLength = trackLength / (steps - 1)
    const draggable = draggableRef.current
    const prevTrackLength = sizesRef.current.trackLength
    sizesRef.current = {
      knobSize,
      trackLength,
      stepLength
    }
    if (draggable && prevTrackLength) {
      // Reposition the handle
      gsap.set(knobEl, { x: (draggable.x / prevTrackLength) * trackLength })
      draggable.update()
    }
  }, [steps]))

  useEffect(() => {
    const trackEl = ref.current
    const knobEl = trackEl.childNodes[0]
    const draggable = new Draggable(knobEl, {
      bounds: trackEl,
      liveSnap: {
        x: x => {
          const { knobSize } = sizesRef.current
          const nearestStepX = getNearestStepX(x)
          if (Math.abs(nearestStepX - x) < knobSize / 2) {
            return nearestStepX
          }
          return x
        },
        y: () => 0
      },
      onDrag () {
        onDrag(getStepIndex(draggable.x), true)
      },
      onDragEnd () {
        const x = draggable.x
        const toX = getNearestStepX(x)
        const { stepLength } = sizesRef.current
        const duration = Math.abs(toX - x) * (3 / stepLength) // 3s per step
        seekToX(toX, { duration })
      }
    })
    draggableRef.current = draggable
    return () => {
      draggable.kill()
    }
  }, [onDrag])

  const seekStep = useCallback((step) => {
    const draggable = draggableRef.current
    if (!draggable) {
      return
    }
    const { stepLength } = sizesRef.current
    const x = step * stepLength
    seekToX(x, { duration: 3 })
  }, [])

  return { ref, seekStep }
}

function useStepLabels (steps, seekStep) {
  return useMemo(() => map(steps, (label, i) => ({
    label,
    onClick: () => seekStep(i)
  })), [steps, seekStep])
}

const Step = ({ label, onClick }) => {
  const classes = useStyles()
  const { hover, ref } = useMouseHover()
  const animationRef = useReversingLottieAnimation(dotAnimationData, hover)
  return (
    <li className={cn(classes.step, { hover })} ref={ref}>
      <button className={classes.label} type='button' onClick={onClick}>{label}</button>
      <div ref={animationRef} className={classes.dot} onClick={onClick} />
    </li>
  )
}

const Timeline = ({ className, steps, onDrag }) => {
  const classes = useStyles()
  const progressRef = useRef()
  const [inViewRef, inView] = useInView()

  const cancelProgress = useCallback(() => {
    if (progressRef.current) {
      progressRef.current.cancel()
    }
  }, [])

  const handleDrag = useCallback((i, userInteraction) => {
    if (userInteraction) {
      cancelProgress()
    }
    onDrag(i, userInteraction)
  }, [onDrag, cancelProgress])

  const { ref, seekStep } = useKnob(steps.length, handleDrag)

  const labels = useStepLabels(steps, seekStep)

  return (
    <nav className={cn(className, classes.timeline)} ref={inViewRef}>
      <ul className={classes.steps} onMouseDown={cancelProgress}>
        <Progress ref={progressRef} steps={steps} seekStep={seekStep} paused={!inView} />
        {map(labels, (label, i) => (
          <Step {...label} key={i} />
        ))}
      </ul>
      <div className={classes.track} ref={ref}>
        <div className={classes.knob}>
          <GripIcon className={classes.knobIcon} />
        </div>
      </div>
    </nav>
  )
}

const gripIconSize = {
  width: 22,
  height: round(22 / gripAspect)
}

const useStyles = createUseStyles({
  timeline: {
    padding: [0, span(1)],
    height: 88,
    position: 'relative',
    userSelect: 'none',
    [theme.breakpoints.up('md')]: {
      padding: [0, span(2, 'md')]
    },
    [theme.breakpoints.up('lg')]: {
      height: vw(88, 'desktop')
    },
    '&::before': {
      // The horizontal line
      content: '""',
      position: 'absolute',
      left: 0,
      width: '100%',
      bottom: 22,
      borderBottom: `2px solid ${theme.colors.white}`,
      opacity: 0.3,
      [theme.breakpoints.up('lg')]: {
        bottom: vw(22, 'desktop')
      }
    }
  },
  steps: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    listStyleType: 'none',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%'
  },
  step: {
    display: 'block',
    margin: 0,
    padding: 0,
    width: 44,
    height: 44,
    position: 'relative',
    textAlign: 'center',
    '&:first-child': {
      textAlign: 'left'
    },
    '&:last-child': {
      textAlign: 'right'
    },
    [theme.breakpoints.up('lg')]: {
      width: vw(44, 'desktop'),
      height: vw(44, 'desktop')
    },
    '&::before': {
      // The small white circle on the horizontal line.
      content: '""',
      display: 'block',
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: theme.colors.white,
      position: 'absolute',
      left: 22 - 5,
      bottom: 22 - 5,
      transition: 'transform 1s',
      [theme.breakpoints.up('lg')]: {
        width: vw(10, 'desktop'),
        height: vw(10, 'desktop'),
        left: vw(22 - 5, 'desktop'),
        bottom: vw(22 - 5, 'desktop')
      }
    },
    '&.hover:before': {
      // We need to shrink this because the animation does not have the white dot and it is larger then the dot in the animation
      // I don't want to make the white dot smaller as the dot becomes too small
      transform: 'scale(0)'
    }
  },
  dot: {
    width: 44,
    height: 44,
    position: 'absolute',
    bottom: 0,
    cursor: 'pointer',
    [theme.breakpoints.up('lg')]: {
      width: vw(44, 'desktop'),
      height: vw(44, 'desktop')
    }
  },
  dotAnimation: {
    pointerEvents: 'none'
  },
  label: {
    appearance: 'none',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontSize: 'inherit',
    display: 'block',
    margin: [-44, 0, 0],
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      marginTop: vw(-44, 'desktop')
    }
  },
  track: {
    position: 'relative',
    width: '100%',
    height: 44,
    top: -44,
    pointerEvents: 'none',
    [theme.breakpoints.up('lg')]: {
      top: vw(-44, 'desktop'),
      height: vw(44, 'desktop')
    }
  },
  knob: {
    display: 'block',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 44,
    height: 44,
    borderRadius: '50%',
    backgroundColor: theme.colors.secondary,
    cursor: 'grab',
    pointerEvents: 'all',
    '&:active': {
      cursor: 'grabbing'
    },
    [theme.breakpoints.up('lg')]: {
      width: vw(44, 'desktop'),
      height: vw(44, 'desktop')
    }
  },
  knobIcon: {
    display: 'block',
    color: theme.colors.white,
    ...gripIconSize,
    marginLeft: (44 - gripIconSize.width) / 2,
    marginTop: (44 - gripIconSize.height) / 2,
    [theme.breakpoints.up('lg')]: {
      marginLeft: vw((44 - gripIconSize.width) / 2, 'desktop'),
      marginTop: vw((44 - gripIconSize.height) / 2, 'desktop'),
      width: vw(gripIconSize.width, 'desktop'),
      height: vw(gripIconSize.height, 'desktop')
    }
  }
}, { name: 'Timeline' })

export default Timeline
