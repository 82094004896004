import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import ResponsiveImage from '../../ResponsiveImage'
import theme from '../../../style/theme'
import { vw } from '../../../style/vw'
import { sliceMarginStyles, span } from '../../../style/span'
import Button from '../../Button'
import { resolveLink } from '../../../helpers/resolveLink'
import RichText from '../../RichText'
import { getForegroundColorFromBackgroundColor } from '../../../style/colorHelper'
import Title from './Title'
import ScrollHint from '../../ScrollHint'
import Contours from '../../Contours'
import Pillars from './Pillars'
import ParallaxElement from '../../ParallaxElement'
import outlineText from '../../../style/outlineText'
import { useSelector } from 'react-redux'
import { isRehydrated } from '../../../redux/selectors'
import { quart } from '../../../style/eases'

const landscapeParallax = {
  ratio: 1.1,
  scale: 1.4
}

const Hero = ({ slice, disableLazy }) => {
  const {
    color: backgroundColor = theme.colors.secondary,
    title,
    category,
    copy,
    image,
    imageVariation,
    scrollHintVariation,
    pillars
  } = slice
  const link = get(slice, ['link', 0])
  const color = getForegroundColorFromBackgroundColor(backgroundColor)
  const classes = useStyles({ color, backgroundColor })
  const hasImage = !pillars && image && imageVariation !== 'none'
  const isPortraitImage = hasImage && imageVariation === '5:7'
  const isLandscapeImage = hasImage && !isPortraitImage
  const isLargeScrollHint = scrollHintVariation === 'large'
  const isSmallScrollHint = scrollHintVariation === 'small'
  const isReady = useSelector(isRehydrated)
  const isButtonBlack = backgroundColor === theme.colors.secondary

  return (
    <header
      className={cn(
        classes.header,
        isLandscapeImage && classes.headerWithLandscapeImage,
        isPortraitImage && classes.headerWithPortraitImage
      )}
    >
      <Contours className={classes.contours} animate backgroundColor={backgroundColor} />
      <div
        className={cn(
          classes.wrapper,
          isLandscapeImage && classes.wrapperWithLandscapeImage,
          isPortraitImage && classes.wrapperWithPortraitImage,
          pillars && classes.wrapperWithPillars
        )}
      >
        <div className={classes.titleContainer}>
          <Title
            title={title}
            category={category}
            large={isPortraitImage}
            scrollHint={isLargeScrollHint}
          />
          {isPortraitImage && (
            <Title
              className={classes.titleOutlineClone}
              title={title}
              category={category}
              large={isPortraitImage}
              // We still need the scroll hit so that the text is layed out the same but we hide it with css
              scrollHint={isLargeScrollHint}
              tag='div'
              screenReaderClone={false}
            />
          )}
        </div>
        {(copy || link) && (
          <div
            className={cn(
              classes.copy,
              isPortraitImage && classes.copyPortrait,
              isSmallScrollHint && classes.copyWithScrollHint
            )}
          >
            {copy && <RichText content={copy} />}
            {link && <Button link={resolveLink(link)} black={isButtonBlack} />}
            {isSmallScrollHint && <ScrollHint className={classes.scrollHint} variation='small' />}
          </div>
        )}
        {hasImage && (
          <ParallaxElement
            ratio={isLandscapeImage ? 1 : 1.2}
            // because we are parallaxing the image we only what to show it once the page is hydrated
            className={cn(classes.image, isPortraitImage && classes.imagePortrait, isReady && classes.imagePortraitReady)}
          >
            <ResponsiveImage
              {...image}
              disableLazy={disableLazy}
              parallax={isLandscapeImage && landscapeParallax}
            />
          </ParallaxElement>
        )}
      </div>
      {pillars && <Pillars className={classes.pillars} slice={pillars} />}
    </header>
  )
}

const IMAGE_Z_INDEX = 1

const useStyles = createUseStyles({
  header: {
    extend: 'headerStaticRules',
    composes: ['clearFix'],
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color || null,
    position: ' relative'
  },
  headerStaticRules: {
    ...sliceMarginStyles
  },
  headerWithLandscapeImage: {
    // The image removes itself from the flow, so we need extra padding (on the wrapper below),
    // plus a bottom margin here to prevent the image overlapping the following slice.
    // Image is 16:9, width is span(12), so height is span(12 / (16 / 9))
    // The bottom padding ends exactly at the vertical middle of the image, so the bottom margin
    // needs to equal half the image height plus an extra margin. The extra margin is the normal
    // space between slices, 2 spans.
    marginBottom: span((12 / 2) / (16 / 9) + 3.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: span((21 / 2) / (16 / 9) + 3.5, 'md')
    }
  },
  headerWithPortraitImage: {
    // Image is 5:7
    marginBottom: span((12 / 2) / (5 / 7) + 3.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: span(5.5, 'md')
    }
  },
  wrapper: {
    padding: [vw(170), span(1)],
    [theme.breakpoints.up('md')]: {
      padding: [vw(263, 'desktop'), span(1, 'md'), vw(263, 'desktop')]
    }
  },
  wrapperWithLandscapeImage: {
    // Further to comment in headerWithLandscapeImage. We want to make the bottom padding equal to
    // half the image height, plus its top margin, so that the header ends exactly halfway down the
    // image.
    paddingBottom: `calc(${span((12 / 2) / (16 / 9))} + ${vw(54)})`,
    [theme.breakpoints.up('md')]: {
      paddingBottom: `calc(${span((21 / 2) / (16 / 9), 'md')} + ${vw(85, 'desktop')})`
    }
  },
  wrapperWithPortraitImage: {
    paddingBottom: `calc(${span((12 / 2) / (5 / 7))} + ${vw(60)})`,
    [theme.breakpoints.up('md')]: {
      // In this case, the copy box and hero image are side-by-side so the bottom padding needs to
      // be reduced by the height of the copy box. Because the copy box height can vary, we can
      // achieve the bottom padding effect by setting a minHeight on the copy box instead.
      paddingBottom: 0
    }
  },
  wrapperWithPillars: {
    // We give the pillars slice a bottom margin below, so don’t need any bottom padding here.
    paddingBottom: 0
  },
  titleContainer: {
    position: 'relative'
  },
  titleOutlineClone: ({ color }) => ({
    ...outlineText(color, 2),
    zIndex: IMAGE_Z_INDEX + 1,
    color: 'transparent',
    position: 'absolute',
    top: 0,
    left: 0,
    '& * svg': {
      visibility: 'hidden'
    }
  }),
  copy: {
    width: span(11),
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: span(7, 'md'),
      marginLeft: span(9, 'md')
    },
    '& > *': {
      marginBottom: span(1),
      [theme.breakpoints.up('md')]: {
        marginBottom: span(1, 'md')
      },
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  copyPortrait: {
    [theme.breakpoints.up('md')]: {
      float: 'left',
      width: span(3, 'md'),
      marginLeft: span(5, 'md'),
      // The minHeight is to make room for the image, see comments on wrapperWithPortraitImage
      minHeight: span((11 / 2) / (5 / 7), 'md')
    }
  },
  copyWithScrollHint: {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      paddingLeft: span(2, 'md'),
      '& > *:nth-last-child(2)': {
        marginBottom: 0
      }
    }
  },
  scrollHint: {
    marginBottom: span(1),
    width: span(2),
    height: span(2),
    [theme.breakpoints.up('md')]: {
      width: span(1, 'md'),
      height: span(1, 'md'),
      marginBottom: 0,
      position: 'absolute',
      left: 0,
      top: 0
    }
  },
  pillars: {
    position: 'relative',
    margin: [vw(120), 'auto', span(3.5)],
    [theme.breakpoints.up('md')]: {
      margin: [vw(150, 'desktop'), 'auto', span(3.5, 'md')]
    }
  },
  image: {
    width: span(12),
    margin: [vw(54), 'auto', 0],
    position: 'absolute',
    zIndex: IMAGE_Z_INDEX,
    [theme.breakpoints.up('md')]: {
      width: span(21, 'md'),
      margin: [vw(85, 'desktop'), 'auto', 0]
    }
  },
  imagePortrait: {
    marginTop: vw(60),
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      transition: `opacity 0.5s ${quart.out}`,
      width: span(11, 'md'),
      margin: [vw(-210, 'desktop'), 'auto', 0, span(9, 'md')]
    }
  },
  imagePortraitReady: {
    opacity: 1
  },
  contours: {
    width: '100vw'
  }
}, { name: 'Hero' })

export default Hero
