import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { sliceMarginStyles, span } from '../../style/span'
import theme from '../../style/theme'
import Contours from '../Contours'

const BackgroundColor = ({ className, backgroundColor, isFirstSlice, isLastSlice, children, contours = true }) => {
  const classes = useStyles({ backgroundColor })

  return (
    <div
      className={cn(
        classes.backgroundColor,
        isFirstSlice && classes.firstSlice,
        isLastSlice && classes.lastSlice,
        className
      )}
    >
      {contours && <Contours animate backgroundColor={backgroundColor} />}
      {children}
    </div>
  )
}

const useStyles = createUseStyles({
  backgroundColor: {
    composes: ['clearFix'],
    extend: 'spacing',
    position: 'relative',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    '& > section': {
      position: 'relative'
    },
    '&:first-child': {
      paddingTop: span(5.5),
      [theme.breakpoints.up('md')]: {
        paddingTop: span(3.5, 'md')
      }
    }
  },
  spacing: {
    extend: [sliceMarginStyles],
    paddingTop: span(2),
    [theme.breakpoints.up('md')]: {
      paddingTop: span(2, 'md')
    }
  },
  lastSlice: {
    marginBottom: 0
  }
}, { name: 'BackgroundColor' })

export default BackgroundColor
