import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { sliceMarginStyles, span } from '../../style/span'
import SplitWordsAnimation from '../SplitWordsAnimation'
import { vw } from '../../style/vw'

const InitiativesTitle = ({ slice }) => {
  const classes = useStyles()
  const { title, text } = slice

  return (
    <header className={classes.initiativesTitle}>
      <SplitWordsAnimation>
        <h2 className={classes.title}>{title}</h2>
      </SplitWordsAnimation>
      <div className={classes.text}>
        {text}
      </div>
    </header>
  )
}

const useStyles = createUseStyles({
  initiativesTitle: {
    extend: [sliceMarginStyles],
    margin: [0, 'auto'],
    width: span(12),
    [theme.breakpoints.up('md')]: {
      width: span(19, 'md'),
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end'
    }
  },
  title: {
    fontSize: vw(72),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(90, 'desktop'),
      width: span(5, 'md'),
      marginRight: span(7, 'md'),
      marginBottom: 0
    }
  },
  text: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: span(5, 'md'),
      marginBottom: 0
    }
  }
}, { name: 'InitiativesTitle' })

export default InitiativesTitle
