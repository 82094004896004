import React, { useState, useEffect, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { span, sliceMarginStyles } from '../../style/span'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import SplitText from 'gsap/SplitText'
import gsap from 'gsap'
import { useInView } from 'react-intersection-observer'
import composeRefs from '../../helpers/composeRefs'
import useWebFontLoaded from '../../hooks/useWebFontLoaded'

const TESTIMONIAL_WIDTH_SPAN = 11

const Testimonial = ({ classes, show: showIfReady, text, attribution, attributionRole }) => {
  const [fontReady, setFontReady] = useState(false)
  const fontReadyRef = useWebFontLoaded(useCallback(() => {
    setFontReady(true)
  }, []))

  const show = showIfReady && fontReady
  const ref = useRef()
  const splitText = useRef({})

  useEffect(() => {
    if (ref.current) {
      splitText.current.text = new SplitText(ref.current.children[0], { type: 'words,lines' })
      gsap.set(splitText.current.text.lines, { overflow: 'hidden' })
      return () => {
        splitText.current.text.revert()
      }
    }
  }, [])

  useEffect(() => {
    if (ref.current) {
      if (show) {
        gsap.to(ref.current, { duration: 0.25, opacity: 1, ease: 'expo.out' })
        gsap.from(splitText.current.text.words, { ease: 'expo.out', yPercent: 100, stagger: 0.01, duration: 1.6, visibility: 'visible' })
      } else {
        gsap.to(ref.current, { duration: 0.25, opacity: 0, ease: 'expo.out' })
      }
    }
  }, [show])

  return (
    <div className={classes.testimonial} ref={composeRefs(ref, fontReadyRef)}>
      <span className={classes.blockquote}>{text}</span>
      <span className={classes.attribution}>{attribution}</span>
      {attributionRole && <span className={classes.attributionRole}>{attributionRole}</span>}
    </div>
  )
}

const Testimonials = ({ className, slice: { testimonials } }) => {
  const classes = useStyles()
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const count = testimonials.length
  const ref = useRef()

  const [inViewRef, inView] = useInView({ threshold: 0.5 })

  useEffect(() => {
    if (inView && selectedIndex === -1) setSelectedIndex(0)
  }, [inView, selectedIndex])

  useEffect(() => {
    // Check ref, that way we do not trigger a state change after an unmount
    if (inView && ref.current && testimonials && testimonials.length > 1) {
      const timer = setTimeout(() => setSelectedIndex(index => (index + 1) % testimonials.length), 4000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [testimonials, selectedIndex, inView])

  return (
    <section className={cn(className, classes.section)} ref={composeRefs(inViewRef, ref)}>
      <div className={classes.testimonials}>
        {testimonials.map((testimonial, index) => (
          <Testimonial
            key={index}
            show={selectedIndex === index}
            classes={classes}
            text={testimonial.text}
            attribution={testimonial.attribution}
            attributionRole={testimonial.attribution_role}
          />
        ))}
      </div>
      {count > 1 && (
        <div className={classes.pagination}>
          {testimonials.map((_, index) => (
            <button key={index} className={cn(classes.paginationButton, { selected: selectedIndex === index })} onClick={() => setSelectedIndex(index)} />
          ))}
        </div>
      )}
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    margin: [0, span(1)],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      margin: span(2, 'md')
    }
  },
  testimonials: {
    display: 'flex',
    '& > *:not(:first-child)': {
      marginLeft: '-100%',
      [theme.breakpoints.up('md')]: {
        marginLeft: span(-TESTIMONIAL_WIDTH_SPAN, 'md')
      }
    }
  },
  testimonial: {
    opacity: 0,
    width: '100%',
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(48, 'desktop'),
      width: span(TESTIMONIAL_WIDTH_SPAN, 'md'),
      minWidth: span(10, 'md')
    }
  },
  blockquote: {
    fontSize: vw(32),
    margin: 0,
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      // textIndent: '2em',
      fontSize: vw(48, 'desktop')
    }
  },
  attribution: {
    fontSize: vw(20),
    marginTop: vw(32),
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginTop: vw(64, 'desktop'),
      fontSize: vw(20, 'desktop')
    }
  },
  attributionRole: {
    fontSize: vw(16),
    marginTop: vw(8),
    display: 'block',
    color: '#585858',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(16, 'desktop'),
      marginTop: vw(16, 'desktop')
    }
  },
  pagination: {
    display: 'flex',
    margin: [vw(32), vw(-4), 0],
    [theme.breakpoints.up('md')]: {
      margin: [0, vw(-4, 'desktop')],
      position: 'absolute',
      right: span(21 - (TESTIMONIAL_WIDTH_SPAN + 2), 'md'),
      bottom: 0
    }
  },
  paginationButton: {
    width: vw(14),
    height: vw(14),
    margin: [0, vw(4)],
    borderRadius: '50%',
    backgroundColor: '#D3D3D3',
    opacity: 0.5,
    transition: 'opacity 0.25s ease-in-out',
    [theme.breakpoints.up('md')]: {
      width: vw(14, 'desktop'),
      height: vw(14, 'desktop'),
      margin: [0, vw(4, 'desktop')]
    },
    '&.selected, &:hover': {
      opacity: 1
    }
  }
}, { name: 'Testimonials' })

export default Testimonials
