import React from 'react'
import { createUseStyles } from 'react-jss'
import { span } from '../../../style/span'
import theme from '../../../style/theme'
import { vw } from '../../../style/vw'
import BrandElements from '../../BrandElements'
import useLottieAnimation from '../../../hooks/useLottieAnimation'

const Shapes = ({ animationData, tweenRef, ...rest }) => {
  const targetRef = useLottieAnimation(tweenRef, animationData)
  return (
    <div ref={targetRef} {...rest} />
  )
}

const BrandElementsBlock = ({ node, tweenRef }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <BrandElements
        className={classes.shapes}
        element={node.element}
        ShapesComponent={Shapes}
        tweenRef={tweenRef}
      />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    padding: [vw(12), 0],
    width: span(5),
    [theme.breakpoints.up('md')]: {
      padding: [vw(40, 'desktop'), 0],
      width: span(2.5, 'md')
    }
  }
}, { name: 'PillarBrandElementsBlock' })

export default BrandElementsBlock
