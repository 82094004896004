import React, { forwardRef, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import animationData from '../../animations/logo.json'
import useLottieAnimation from '../../hooks/useLottieAnimation'
import { vw } from '../../style/vw'
import theme from '../../style/theme'
import gsap from 'gsap'

const aspectRatio = animationData.w / animationData.h

const AnimatedLogo = forwardRef(({ color }, ref) => {
  const classes = useStyles()
  const tweenRef = useRef()
  const targetRef = useLottieAnimation(tweenRef, animationData)

  useEffect(() => {
    if (tweenRef.current) {
      tweenRef.current.resume()
    }
  }, [])

  useEffect(() => {
    if (tweenRef.current) {
      gsap.set(targetRef.current.querySelectorAll('path'), { fill: color })
    }
  }, [color])

  return (
    <div ref={targetRef} className={classes.logo} />
  )
})

const useStyles = createUseStyles({
  logo: {
    width: vw(22 * aspectRatio),
    height: vw(22),
    fill: 'currentColor',
    [theme.breakpoints.up('md')]: {
      width: vw(22 * aspectRatio, 'desktop'),
      height: vw(22, 'desktop')
    }
  }
}, { name: 'AnimatedLogo' })

export default AnimatedLogo
