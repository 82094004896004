import get from 'lodash/get'
import find from 'lodash/find'
import map from 'lodash/map'
import last from 'lodash/last'
import defaults from 'lodash/defaults'
import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { getCurrentRoutePath, isPreviewRoute } from './location'
import theme from '../../style/theme'

export const isContentBusy = state => !!(state.content.page.busy || state.content.global.busy)
export const getContentError = state => state.content.page.error || state.content.global.error
export const getPageContent = state => get(state, ['content', 'page', 'content'])
export const isContentError404 = state => get(getContentError(state), ['status']) === 404

export const getGlobalContent = state => state.content.global.content
export const getFooter = state => get(getGlobalContent(state), ['footer'])
export const isContentReadyOrError = state => !isContentBusy(state) && !!(
  (getPageContent(state) && getGlobalContent(state)) || getContentError(state)
)
export const getTags = state => get(getGlobalContent(state), ['tags'])

export const getHeaderContent = state => get(getGlobalContent(state), ['header'])
export const getSiteTitle = state => get(getGlobalContent(state), ['title'])
export const getSocialLinks = state => get(getGlobalContent(state), ['socialLinks'])

export const getCookiesBannerContent = state => get(getGlobalContent(state), ['cookies'])

export const getPageId = state => get(getPageContent(state), ['id'])
export const getPageSlug = state => get(getPageContent(state), ['slug'])
export const getPageType = state => get(getPageContent(state), ['type'])
export const getPageTitle = state => get(getPageContent(state), ['title'])
export const getPageSubTitle = state => get(getPageContent(state), ['subtitle'])
export const getPageMetaData = createSelector(
  getGlobalContent,
  getPageContent,
  getCurrentRoutePath,
  (global, page, path) => {
    const defaultCanonicalUrl = `${process.env.REACT_APP_CANONICAL_URL_BASE}${path}`
    return defaults(
      {},
      get(page, ['seo'], {}),
      {
        // these are the defaults for fields that aren't set above
        metaTitle: get(page, ['title']),
        siteName: get(global, ['title']),
        ogUrl: defaultCanonicalUrl,
        canonicalUrl: defaultCanonicalUrl
      }
    )
  }
)

export const isHomepage = createSelector(
  getPageSlug,
  (slug) => slug === 'home'
)

export const getPageHeadTitle = createSelector(
  getGlobalContent,
  getPageMetaData,
  getPageTitle,
  (global, meta, title = get(meta, ['title'])) => {
    const globalTitle = get(global, ['title'])
    if (!title) {
      return globalTitle
    }
    if (!globalTitle) {
      return title
    }
    return `${title} – ${globalTitle}`
  }
)

export const getPageSlices = createSelector(
  getPageContent,
  (page) => {
    return (page && page.slices) ? map(page.slices, (slice, index) => ({
      id: `${page.id}-${index}`,
      ...slice
    })) : undefined
  }
)

// We still need to create a selector factory here. 'useSelector' is expecting a function with
// one argument, the state
export const getSliceByIdSelector = createCachedSelector(
  (id) => id,
  (id) => {
    return createSelector(
      getPageSlices,
      slices => find(slices, slice => slice.id === id)
    )
  }
)(
  (id) => id
)

export const getPreviewMode = state => get(state, ['content', 'preview'])

export const isPreviewMode = createSelector(
  isPreviewRoute,
  getPreviewMode,
  (previewRoute, previewMode) => previewRoute || previewMode
)

export const getHeroBackgroundColor = createSelector(
  getPageSlices,
  slices => {
    const hero = find(slices, { type: 'hero_slice' })
    return get(hero, ['color'], theme.colors.secondary)
  }
)

export const getHeader = state => get(getGlobalContent(state), 'header')

export const getMenuItems = createSelector(
  getGlobalContent,
  getCurrentRoutePath,
  (global, path) => {
    const menu = global.header.menu
    if (!menu) return []
    return menu.map(item => ({
      ...item,
      selected: get(item, ['reference', 'slug']) === last(path.split('/'))
    }))
  }
)

export const isRehydrated = state => state.content.rehydrated
