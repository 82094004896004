import React, { useCallback, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import SplitText from 'gsap/SplitText'
import { useInView } from 'react-intersection-observer'
import composeRefs from '../helpers/composeRefs'
import wrapAll from '../helpers/wrapAll'
import unwrap from '../helpers/unwrap'
import useWebFontLoaded from '../hooks/useWebFontLoaded'

gsap.registerPlugin(SplitText)

function useSplitWordsAnimation ({ linesOnly = false }) {
  const [inViewRef, inView] = useInView({ threshold: 0.5, triggerOnce: true })
  const [fontsReady, setFontsReady] = useState(false)
  const fontsReadyRef = useWebFontLoaded(useCallback(() => {
    setFontsReady(true)
  }, []))

  const locals = useRef({}).current

  const mountRef = useCallback((el) => {
    if (el) {
      if (!fontsReady) {
        return
      }
      locals.split = new SplitText(el, { type: linesOnly ? 'lines' : 'words,lines' })
      gsap.set(locals.split.lines, { overflow: 'hidden' })
      if (linesOnly) {
        locals.targets = locals.split.lines.map(
          line => wrapAll(line.childNodes, document.createElement('div'))
        )
      } else {
        locals.targets = locals.split.words
      }
      gsap.set(locals.targets, { yPercent: 100, visibility: 'hidden' })
      gsap.set(el, { visibility: 'visible' })
      locals.el = el
    } else if (locals.split) {
      if (linesOnly) {
        locals.targets.forEach(innerLine => {
          unwrap(innerLine)
        })
      }
      locals.split.revert()
      delete locals.targets
      delete locals.split
      delete locals.el
    }
  }, [locals, linesOnly, fontsReady])

  const inViewAndReady = inView && fontsReady
  useEffect(() => {
    if (!locals.el) {
      return
    }

    if (inViewAndReady) {
      gsap.to(
        locals.targets,
        { ease: 'expo.out', yPercent: 0, stagger: 0.06, duration: 1.2, visibility: 'visible' }
      )
    }
  }, [inViewAndReady])

  return composeRefs(inViewRef, fontsReadyRef, mountRef)
}

const SplitWordsAnimation = ({ children, srOnlyContent, linesOnly = false, screenReaderClone = true }) => {
  const classes = useStyles()
  const child = React.Children.only(children)
  const content = child.props.children
  const ref = useSplitWordsAnimation({ linesOnly })
  return React.cloneElement(child, {}, (
    <>
      {screenReaderClone && <div className='srOnly'>{srOnlyContent || content}</div>}
      <div className={classes.splitWords} ref={ref} aria-hidden='true'>
        {content}
      </div>
    </>
  ))
}

const useStyles = createUseStyles({
  splitWords: {
    visibility: 'hidden',
    '& > div': {
      marginBottom: '-0.1em',
      '& > div': {
        paddingBottom: '0.1em'
      }
    }
  },
  srOnly: {
    composes: ['srOnly']
  }
})

export default SplitWordsAnimation
