export const NAV_MOUNT = 'NAV_MOUNT'
export const NAV_UNMOUNT = 'NAV_UNMOUNT'

export const TOGGLE_MENU = 'TOGGLE_MENU'
export const CLOSE_MENU = 'CLOSE_MENU'
export const OPEN_MENU = 'OPEN_MENU'
export const NAV_SET_COLORS = 'NAV_SET_COLORS'

export const navMount = (payload) => ({ type: NAV_MOUNT, payload })
export const navUnmount = (payload) => ({ type: NAV_UNMOUNT, payload })

export const setNavColors = (payload) => ({ type: NAV_SET_COLORS, payload })

export const toggleMenu = () => ({ type: TOGGLE_MENU })
export const closeMenu = () => ({ type: CLOSE_MENU })
export const openMenu = () => ({ type: OPEN_MENU })
