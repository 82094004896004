import React, { useRef, useCallback, useEffect } from 'react'
import detectIt from 'detect-it'
import useResizeObserver from '../hooks/useResizeObserver'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'

const useStyles = createUseStyles({
  sizeBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1,
    visibility: 'hidden'
  },
  box: {
    width: '100%',
    '&.is-active': {
      position: 'fixed'
    }
  }
}, { name: 'SmoothScroll' })

const SmoothScroll = ({ children, className, enabled = true }) => {
  const classes = useStyles()

  // refs
  const ref = useRef()
  const elDummy = useRef()

  // physics
  let y = 0
  let oy = 0
  let vy = 0

  const isFirefox = useRef(typeof navigator !== 'undefined' ? navigator.userAgent.toLowerCase().indexOf('firefox') > -1 : false)
  const easing = isFirefox.current ? 0.35 : 0.12
  const easingTrackpad = 0.28

  const disablePointerEvents = true
  let pointerDisabled = false

  let mode = 'mouse'
  let dragging = false

  const onTouchStart = (e) => {
    dragging = false
    mode = 'touch'
  }

  const handleMouseWheel = (e) => {
    if (!mode || mode === 'touch') mode = 'mouse'

    dragging = false
  }

  const tick = (force) => {
    const translate = `translate3d(0,${-SmoothScroll.y}px,0)`
    ref.current.style.transform = translate

    if (enabled) {
      if (force) {
        y = window.pageYOffset
        vy = 0
      } else if (!dragging && (mode === 'touch' || mode === 'trackpad')) {
        y += (window.pageYOffset - y) * easingTrackpad
        vy = window.pageYOffset - y
      } else {
        y += (window.pageYOffset - y) * easing
        vy = window.pageYOffset - y
      }

      SmoothScroll.velocity = vy

      // disable pointer events while scrolling
      if (disablePointerEvents) {
        if (!pointerDisabled && Math.abs(vy) > 10) {
          pointerDisabled = true
          ref.current.style.pointerEvents = 'none'
        } else if (pointerDisabled && Math.abs(vy) <= 10) {
          pointerDisabled = false
          ref.current.style.pointerEvents = ''
        }
      }
    }

    const yRound = ((y + 0.01) * 100 | 0) / 100 // rounding values

    if (yRound !== oy) {
      SmoothScroll.y = yRound
    }

    oy = yRound
  }

  const wheelEvent = useRef()
  const firstResize = useRef(true)

  if (typeof document !== 'undefined') {
    wheelEvent.current = 'onwheel' in document ? 'wheel'
      : 'onmousewheel' in document ? 'mousewheel'
        : 'DOMMouseScroll'

    // enable smoothscrolling for non touch device only
    if (detectIt.primaryInput !== 'touch') {
      const resize = useCallback((entry) => {
        gsap.set(elDummy.current, { height: entry.height })

        if (ref.current && firstResize.current) {
          const passive = detectIt.passiveEvents ? { passive: true } : false
          ref.current.addEventListener(wheelEvent.current, handleMouseWheel, passive)
          ref.current.addEventListener('touchstart', onTouchStart, passive)
          ref.current.classList.add('is-active')

          y = oy = window.pageYOffset

          firstResize.current = false
        }
        tick(true)
      }, [])
      useResizeObserver(ref, resize)

      useEffect(() => {
        const tickerLoop = () => tick()
        gsap.ticker.add(tickerLoop)
        return () => {
          gsap.ticker.remove(tickerLoop)
        }
      }, [])
    }
  }

  return (
    <div>
      <div className={classes.box} ref={ref}>
        {children}
      </div>
      <div className={classes.sizeBox} ref={elDummy} />
    </div>
  )
}

SmoothScroll.y = 0
SmoothScroll.velocity = 0
SmoothScroll.resolveY = () => detectIt.primaryInput === 'touch' ? window.pageYOffset : SmoothScroll.y

export default SmoothScroll
