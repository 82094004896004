import inDOM from 'dom-helpers/canUseDOM'
import {
  BREAKPOINT_CHANGED,
  REHYDRATED,
  ROUTE_CONTENT,
  CLOSE_MENU,
  OPEN_MENU,
  TOGGLE_MENU,
  NAV_SET_COLORS
} from '../actions'
import { current } from '../../style/breakpoints'

const initialState = {
  breakpoint: current(),
  breakpointSet: inDOM,
  menuOpen: false,
  navColors: null
}

const layout = (state = initialState, action) => {
  switch (action.type) {
    case BREAKPOINT_CHANGED:
      return {
        ...state,
        breakpoint: action.payload.breakpoint,
        breakpointSet: true
      }
    case REHYDRATED:
      return {
        ...state,
        breakpointSet: true
      }
    case ROUTE_CONTENT:
    case CLOSE_MENU:
      return {
        ...state,
        menuOpen: false
      }
    case OPEN_MENU:
      return {
        ...state,
        menuOpen: true
      }
    case TOGGLE_MENU:
      return {
        ...state,
        menuOpen: !state.menuOpen
      }
    case NAV_SET_COLORS:
      return {
        ...state,
        navColors: action.payload
      }
    default:
      return state
  }
}

export default layout
