import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import theme from '../style/theme'
import { ReactComponent as SvgLogoLockup, aspectRatio } from '../images/logo-lockup.svg'
import { vw } from '../style/vw'
import Link from './Link'
import { getSiteTitle } from '../redux/selectors'

const LogoLockup = ({ className }) => {
  const classes = useStyles()
  const title = useSelector(getSiteTitle)

  return (
    <Link to='/' className={cn(className, classes.logoLink)}>
      <SvgLogoLockup className={classes.lockup} />
      <span className={classes.text}>{title}</span>
    </Link>
  )
}

const useStyles = createUseStyles({
  logoLink: {
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    color: 'inherit',
    textDecoration: 'none',
    alignItems: 'center',
    textTransform: 'uppercase',
    minHeight: 42
  },
  lockup: {
    width: vw(22 * aspectRatio),
    height: vw(22),
    fill: 'currentColor',
    [theme.breakpoints.up('md')]: {
      width: vw(22 * aspectRatio, 'desktop'),
      height: vw(22, 'desktop')
    }
  },
  text: {
    composes: ['srOnly']
  }
}, { name: 'LogoLockup' })

export default LogoLockup
