import { keys } from '../../style/breakpoints'
import { createSelector } from 'reselect'
import { getHeroBackgroundColor } from './content'
import { getForegroundColorFromBackgroundColor, getSecondaryColorFromBackgroundColor } from '../../style/colorHelper'

export const getCurrentBreakpoint = state => state.layout.breakpoint
export const isCurrentBreakpointAtLeast = (state, breakpoint) => (
  keys.indexOf(getCurrentBreakpoint(state)) >= keys.indexOf(breakpoint)
)
export const isBreakpointSet = state => state.layout.breakpointSet
export const isCurrentBreakpointMobile = state => !isCurrentBreakpointAtLeast(state, 'md')

export const isMenuOpen = state => state.layout.menuOpen

export const getNavColors = createSelector(
  state => state.layout.navColors,
  getHeroBackgroundColor,
  (navColor, heroBackgroundColor) => {
    const color = getForegroundColorFromBackgroundColor(navColor || heroBackgroundColor)
    const highlightColor = getSecondaryColorFromBackgroundColor(navColor || heroBackgroundColor)
    return { color, highlightColor }
  }
)
