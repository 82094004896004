export const BREAKPOINT_CHANGED = 'BREAKPOINT_CHANGED'
export const BRIGHT_SLICES_MOUNTED = 'BRIGHT_SLICES_MOUNTED'

export const breakpointChanged = breakpoint => ({
  type: BREAKPOINT_CHANGED,
  payload: {
    breakpoint
  }
})

export const brightSlicesMounted = slice => ({
  type: BRIGHT_SLICES_MOUNTED
})
