import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import ResponsiveImage from '../ResponsiveImage'
import { sliceMarginStyles, span } from '../../style/span'
import theme from '../../style/theme'
import RichText from '../RichText'
import SocialLink from '../SocialLink'
import { vw } from '../../style/vw'
import round from '../../helpers/round'

const Bios = ({ className, slice }) => {
  const classes = useStyles()
  const { bios } = slice
  return (
    <section className={cn(className, classes.bios)}>
      {map(bios, ({ id, image, name, role, socialLinks, copy }) => (
        <article className={classes.bio} key={id}>
          <ResponsiveImage {...image} className={classes.image} />
          <h3 className={classes.name}>{name}</h3>
          {role && <h4 className={classes.role}>{role}</h4>}
          {!isEmpty(socialLinks) && (
            <ul className={classes.socialLinks}>
              {map(socialLinks, ({ id, url, title }) => (
                <li className={classes.socialLinkItem} key={id}>
                  <SocialLink className={classes.socialLink} url={url} text={title} />
                </li>
              ))}
            </ul>
          )}
          {copy && <RichText className={classes.copy} content={copy} />}
        </article>
      ))}
    </section>
  )
}

const useStyles = createUseStyles({
  bios: {
    margin: [0, 'auto'],
    width: span(12),
    [theme.breakpoints.up('md')]: {
      width: span(19, 'md'),
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  bio: {
    extend: [sliceMarginStyles],
    width: '100%',
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      width: span(7, 'md'),
      '&:nth-child(2n)': {
        marginLeft: span(3, 'md')
      }
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  image: {
    marginBottom: span(1.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: span(1, 'md')
    }
  },
  role: {
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontSize: 'inherit',
    textTransform: 'none',
    marginBottom: '1em',
    [theme.breakpoints.up('md')]: {
      marginBottom: `${round(30 / 14)}em`
    }
  },
  socialLinks: {
    display: 'block',
    listStyleType: 'none',
    padding: 0,
    margin: [0, `${round(-10 / 14)}em`, `${round(4 / 14)}em`],
    [theme.breakpoints.up('md')]: {
      margin: [0, `${round(-5 / 14)}em`, `${round(15 / 14)}em`]
    }
  },
  socialLinkItem: {
    display: 'inline-block',
    margin: 0,
    padding: 0
  },
  socialLink: {
    color: 'inherit',
    [theme.breakpoints.up('md')]: {
      padding: '12.5%'
    }
  },
  copy: {
    '& p, & ul': {
      fontSize: 'inherit'
    }
  }
}, { name: 'Bios' })

export default Bios
