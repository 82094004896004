import color from 'color'
import theme from './theme'

export const getForegroundColorFromImage = (image, defaultColor = theme.colors.black) => {
  const { palette: { dominant: { title } } } = image
  if (!title) {
    return defaultColor
  }
  const c = color(title)
  return c.isLight() ? theme.colors.white : theme.colors.black
}

export const getForegroundColorFromBackgroundColor = (bg, defaultColor = theme.colors.black) => {
  if (!bg) return defaultColor
  const c = color(bg)
  return c.isLight() ? theme.colors.black : theme.colors.white
}

export const getSecondaryColorFromBackgroundColor = (bc, defaultColor = theme.colors.secondary) => {
  if (!bc) return defaultColor
  const c = color(bc)
  if (c.contrast(color(defaultColor)) >= 1.5) {
    return defaultColor
  }
  if (c.contrast(color(theme.colors.white)) >= 1.5) {
    return theme.colors.white
  }
  return theme.colors.black
}
