import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import RichText from '../RichText'
import { sliceMarginStyles, span } from '../../style/span'
import theme from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import ParallaxElement from '../ParallaxElement'
import { vw } from '../../style/vw'

export const IMAGE_ASPECT = 9 / 13

const RichTextWithImageSlice = forwardRef(({ className, slice, lastSliceInBackground }, ref) => {
  const {
    rich_text: { text },
    image_desktop_order: imageDesktopOrder,
    image_mobile_order: imageMobileOrder,
    image,
    small_image: smallImage
  } = slice
  const rightAligned = imageDesktopOrder === 'right'
  const bottomAligned = imageMobileOrder === 'bottom'
  const classes = useStyles()

  return (
    <section className={cn(classes.content, className, { right: rightAligned, bottom: bottomAligned })}>
      <ParallaxElement ratio={1} ease={0.2}>
        <ResponsiveImage {...image} className={classes.image} aspect={IMAGE_ASPECT} />
      </ParallaxElement>
      <div className={cn(classes.richText, { lastSliceInBackground })}>
        <RichText ref={ref} content={text} className={cn(smallImage && classes.tight)} />
        {smallImage && (
          <ParallaxElement ratio={1.1} ease={0.1} className={classes.smallImageParallax}>
            <ResponsiveImage {...smallImage} className={classes.smallImage} />
          </ParallaxElement>
        )}
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  content: {
    extend: [sliceMarginStyles],
    margin: [0, 'auto'],
    display: 'flex',
    padding: [0, span(1)],
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: [0, span(2, 'md')]
    },
    '&.bottom': {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse'
      },
      '& $image': {
        marginBottom: 0,
        marginTop: vw(32),
        [theme.breakpoints.up('md')]: {
          marginTop: 0
        }
      }
    },
    '&.right': {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row-reverse'
      },
      '& $richText': {
        [theme.breakpoints.up('md')]: {
          padding: [0, span(3, 'md'), 0, 0]
        }
      }
    }
  },
  image: {
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      width: span(9, 'md')
    }
  },
  richText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      padding: [0, span(1, 'md'), 0, span(3, 'md')],
      width: span(10, 'md')
    },
    '&.lastSliceInBackground': {
      justifyContent: 'flex-start',
      paddingTop: span(1),
      [theme.breakpoints.up('md')]: {
        paddingTop: span(1, 'md')
      }
    }
  },
  tight: {
    [theme.breakpoints.up('md')]: {
      padding: [0, span(1, 'md')]
    },
    '& .brand-element': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      }
    },
    '& svg': {
      [theme.breakpoints.up('md')]: {
        margin: [0, span(-1, 'md')]
      }
    }
  },
  smallImageParallax: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginTop: span(-3, 'md')
    }
  },
  smallImage: {
    marginTop: span(1),
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      width: span(5, 'md')
    }
  }
}, { name: 'RichTextWithImageSlice' })

export default RichTextWithImageSlice
