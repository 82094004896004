import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import { span } from '../../style/span'

const TinyCategoryTitle = ({ children }) => {
  const classes = useStyles()
  const child = React.Children.only(children)
  return React.cloneElement(child, {
    className: cn(child.props.className, classes.tinyCategoryTitle)
  }, <span>{child.props.children}</span>)
}

const useStyles = createUseStyles({
  tinyCategoryTitle: {
    display: 'block',
    fontSize: 12,
    letterSpacing: '-0.01em',
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    marginBottom: 20,
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      fontSize: 'inherit',
      verticalAlign: 'top',
      height: '0.8em',
      marginBottom: 0,
      padding: ['0.0934em', '0.0934em', 0, '0.0384em'],
      minWidth: span(2, 'md'),
      '& > span': {
        display: 'block',
        fontSize: 12,
        letterSpacing: '-0.01em'
      }
    },
    [theme.breakpoints.up('lg')]: {
      '& > span': {
        fontSize: vw(12, 'desktop')
      }
    }
  }
}, { name: 'TinyCategoryTitle' })

export default TinyCategoryTitle
