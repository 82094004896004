import React from 'react'
import flatten from 'lodash/flatten'
import map from 'lodash/map'

export default function newlinesToBr (text) {
  if (!text) {
    return text
  }
  const lines = text.split(/\r?\n/)
  if (lines.length <= 1) {
    return text
  }
  return flatten(map(lines, (line, i) =>
    i < lines.length - 1
      ? [line, <br key={i} />]
      : line
  ))
}
