/**
 * API URLs.
 * These need to be domain-relative on the client (i.e. /api) but absolute on the server. The trick we use to achieve
 * that is:
 *
 * On the server, the CONTENT_API_URL environment variable is defined, so we use that.
 *
 * On the browser, CONTENT_API_URL is undefined, because only variables prefixed with REACT_APP_ get inlined (as
 * documented at https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables), so we use the
 * setting found in Redux state.
 *
 * This way, the Redux state does not differ between client and server.
 *
 */
export const getApiUrl = state => process.env.API_URL || state.config.api
export const getGlobalContentApiUrl = state => getApiUrl(state) + '/global'
export const getContentApiUrl = state => getApiUrl(state) + '/content'
export const getPreviewApiUrl = state => getApiUrl(state) + '/preview'
export const isApiAccessTokenInvalid = state => state.config.invalidToken
