import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import theme from '../style/theme'
import { getFooter } from '../redux/selectors'
import { span } from '../style/span'
import LogoLockup from './LogoLockup'
import InViewShapes from './InViewShapes'
import shapesAnimation from '../animations/footerShapes.json'
import LinkGroups from './LinkGroups'
import Contours from './Contours'

function Footer () {
  const { title, links, socialTitle } = useSelector(getFooter)
  const classes = useStyles({ groupsCount: links.length + 1 })
  return (
    <footer className={classes.footer}>
      <Contours animate backgroundColor={theme.colors.footer} />
      <LogoLockup className={classes.logoLockup} />
      <InViewShapes className={classes.shapes} animationData={shapesAnimation} />
      <blockquote className={classes.quote}>{title}</blockquote>
      <LinkGroups links={links} socialTitle={socialTitle} />
    </footer>
  )
}

const useStyles = createUseStyles({
  footer: {
    position: 'relative',
    backgroundColor: theme.colors.footer,
    padding: [span(2.5), span(1), span(1)],
    [theme.breakpoints.up('md')]: {
      padding: [span(3.5, 'md'), span(2, 'md'), span(2, 'md')]
    }
  },
  logoLockup: {
    position: 'relative',
    marginBottom: span(4.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: span(3.5, 'md')
    }
  },
  shapes: {
    float: 'right',
    width: span(4),
    height: span(4),
    [theme.breakpoints.up('md')]: {
      width: span(4, 'md'),
      height: span(4, 'md')
    }
  },
  quote: {
    extend: [theme.global[theme.headingsSelector], theme.global.h2],
    position: 'relative',
    width: span(8),
    marginBottom: span(4.5),
    [theme.breakpoints.up('md')]: {
      width: span(10, 'md'),
      marginBottom: span(3.5, 'md')
    }
  }
}, { name: 'Footer' })

export default Footer
