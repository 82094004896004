import { oneLineTrim } from 'common-tags'

export const unquotedBodyFace = 'National 2'
export const bodyFace = `"${unquotedBodyFace}"`
export const body = `${bodyFace}, "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif`
export const bodyFontWeight = 350
export const unquotedHeadingsFace = 'National 2 Compressed'
export const headingsFace = `"${unquotedHeadingsFace}"`
export const headings = `${headingsFace}, "Arial Narrow", Arial, sans-serif`
export const headingsFontWeight = 500

export const preload = [
  require('./national-2-web-light.woff2'),
  require('./national-2-web-regular.woff2'),
  require('./national-2-compressed-web-medium.woff2'),
  require('./national-2-web-bold.woff2')
].filter(item => !item.startsWith('data:'))

export const faces = [{
  fontFamily: bodyFace,
  src: oneLineTrim`
    url('${require('./national-2-web-light.eot')}?#iefix') format('embedded-opentype'),
    url('${require('./national-2-web-light.woff2')}') format('woff2'),
    url('${require('./national-2-web-light.woff')}') format('woff')`,
  fontWeight: bodyFontWeight,
  fontStyle: 'normal'
}, {
  fontFamily: bodyFace,
  src: oneLineTrim`
    url('${require('./national-2-web-regular.eot')}?#iefix') format('embedded-opentype'),
    url('${require('./national-2-web-regular.woff2')}') format('woff2'),
    url('${require('./national-2-web-regular.woff')}') format('woff')`,
  fontWeight: 'normal',
  fontStyle: 'normal'
}, {
  fontFamily: bodyFace,
  src: oneLineTrim`
    url('${require('./national-2-web-bold.eot')}?#iefix') format('embedded-opentype'),
    url('${require('./national-2-web-bold.woff2')}') format('woff2'),
    url('${require('./national-2-web-bold.woff')}') format('woff')`,
  fontWeight: 'bold',
  fontStyle: 'normal'
}, {
  fontFamily: headingsFace,
  src: oneLineTrim`
    url('${require('./national-2-compressed-web-medium.eot')}?#iefix') format('embedded-opentype'),
    url('${require('./national-2-compressed-web-medium.woff2')}') format('woff2'),
    url('${require('./national-2-compressed-web-medium.woff')}') format('woff')`,
  fontWeight: headingsFontWeight,
  fontStyle: 'normal'
}]
