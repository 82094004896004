/* global window */
import gsap from 'gsap'

export default function smoothScrollTo (y) {
  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({ top: y, behavior: 'smooth' })
  } else {
    gsap.to(window, { duration: 1, scrollTo: { y }, ease: 'power1.out' })
  }
}
