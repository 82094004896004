import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { sliceMarginStyles, span } from '../../style/span'
import theme from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import Button from '../Button'
import Link from '../Link'
import { resolveLink } from '../../helpers/resolveLink'
import { vw } from '../../style/vw'
import { useInView } from 'react-intersection-observer'
import gsap from 'gsap'

const ProjectHeroSlice = ({ className, slice }) => {
  const {
    title,
    link,
    pillar,
    image,
    image_desktop_order: imageDesktopOrder
  } = slice
  const classes = useStyles()
  const rightAligned = imageDesktopOrder === 'right'

  const [contentRef, inView, entry] = useInView({ threshold: 0.5 })
  useEffect(() => {
    if (inView) {
      gsap.to(entry.target.childNodes, { ease: 'expo.out', opacity: 1, y: 0, stagger: 0.1, duration: 1.6 })
    }
  }, [inView, entry])

  return (
    <section className={cn(classes.section, { right: rightAligned })}>
      <Link link={resolveLink(link[0])}>
        <ResponsiveImage {...image} className={classes.image} parallax={theme.parallax} />
      </Link>
      <div className={classes.content} ref={contentRef}>
        {pillar && <span className={classes.pillar}>{pillar}</span>}
        {title && (
          <Link className={classes.titleLink} link={resolveLink(link[0])}>
            <h3 className={classes.title}>{title}</h3>
          </Link>
        )}
        {link && <Button link={resolveLink(link[0])} />}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles],
    margin: [0, 'auto'],
    padding: [0, span(1)],
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      padding: [0, span(2, 'md')]
    },
    '&.right': {
      flexDirection: 'row-reverse',
      '& $content': {
        padding: [0, span(2, 'md'), 0, 0]
      }
    }
  },
  image: {
    flexShrink: 0,
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      width: span(11, 'md')
    }
  },
  content: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      padding: [0, 0, 0, span(2, 'md')]
    },
    '& > *': {
      opacity: 0,
      transform: 'translateY(15vw)'
    }
  },
  titleLink: {
    textDecoration: 'none',
    color: 'currentColor'
  },
  title: {
    fontSize: vw(42),
    marginBottom: vw(24),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(72, 'desktop'),
      marginBottom: vw(64, 'desktop')
    }
  },
  pillar: {
    display: 'block',
    textTransform: 'uppercase',
    position: 'relative',
    paddingLeft: '1.2em',
    fontSize: vw(12),
    marginBottom: vw(24),
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: '50%',
      height: 1,
      width: '0.8em',
      backgroundColor: 'currentColor'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop'),
      marginBottom: vw(64, 'desktop')
    }
  }
}, { name: 'ProjectHeroSlice' })

export default ProjectHeroSlice
