import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import map from 'lodash/map'
import each from 'lodash/each'
import range from 'lodash/range'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import reverse from 'lodash/reverse'
import FontFaceObserver from 'fontfaceobserver'
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import RichText from '../../RichText'
import ResponsiveImage from '../../ResponsiveImage'
import { span, sliceMarginStyles } from '../../../style/span'
import theme from '../../../style/theme'
import Timeline from './Timeline'
import BrandElements from './BrandElements'

const dragSmoothFactor = 0.12

const itemChildNames = [
  'container',
  'title',
  'copy',
  'image1',
  'image2',
  'shapes'
]

function useItemRefs (length) {
  const ref = useRef([])
  if (ref.current.length !== length) {
    ref.current = map(range(length), i => ref.current[i] ||
      fromPairs(map(itemChildNames, childName => [
        `${childName}Ref`,
        ({ current: null })
      ]))
    )
  }
  return ref.current
}

const splitTitle = (el) => {
  const split = new SplitText(el, { type: 'words,lines' })
  gsap.set(split.lines, { overflow: 'hidden' })
  gsap.set(split.words, { yPercent: 100, visibility: 'hidden' })
  return split
}

function useTimeline (steps) {
  const [fontReady, setFontReady] = useState(false)
  useEffect(() => {
    const loader = new FontFaceObserver(theme.fonts.unquotedHeadingsFace, { weight: theme.fonts.headingsFontWeight })
    loader.load().then(() => {
      setFontReady(true)
    })
  }, [])

  const stepRef = useRef(0)
  const smoothedStepRef = useRef(0)
  const handleDrag = useCallback((stepIndex) => {
    stepRef.current = stepIndex
  }, [])

  const refs = useItemRefs(steps)

  useEffect(() => {
    if (!fontReady) {
      return
    }
    const timeline = gsap.timeline({ paused: true })
    each(refs, (stepRefs, i) => {
      const [containerEl, titleEl, copyEl, image1El, image2El, shapes] = map(
        itemChildNames, childName => get(stepRefs, [`${childName}Ref`, 'current'])
      )
      const split = splitTitle(titleEl)
      const enterDuration = 0.6
      const exitDuration = 1 - enterDuration
      const exit = i + 1
      const enter = exit - enterDuration
      // Enter from the left
      const items = [copyEl]
      if (i % 2 === 0) {
        items.push(image1El, image2El)
      } else {
        items.push(image2El, image1El)
      }
      timeline
        .fromTo(
          split.words,
          { yPercent: 100 },
          { yPercent: 0, ease: 'expo.out', stagger: { amount: 0.15 }, duration: enterDuration - 0.15, visibility: 'visible' },
          enter
        )
        .fromTo(
          items,
          { y: 30, autoAlpha: 0 },
          { y: 0, autoAlpha: 1, ease: 'expo.out', stagger: { amount: 0.1 }, duration: enterDuration - 0.1 },
          enter
        )
        .to(
          shapes,
          {
            progress: 1,
            duration: enterDuration
          },
          enter
        )
        // Leave to the right
        .fromTo(
          split.words,
          { yPercent: 0 },
          { yPercent: -100, ease: 'expo.in', stagger: { amount: 0.08 }, duration: 0.4 - 0.08, autoAlpha: 0 },
          exit
        )
        .fromTo(
          reverse([...items]),
          { y: 0, autoAlpha: 1 },
          { y: -30, autoAlpha: 0, ease: 'expo.in', stagger: { amount: 0.1 }, duration: exitDuration - 0.05 - 0.1 },
          exit
        )
        .to(
          shapes,
          {
            progress: 0,
            duration: 0.3
          },
          exit + 0.1
        )
      gsap.set(containerEl, { visibility: 'visible' })
      gsap.set([copyEl, image1El, image2El], { autoAlpha: 0 })
    })
    timeline.tweenTo(1, { ease: 'none', delay: 0.3 })

    // Smooth out the input
    const tick = () => {
      const prevSmoothedStep = smoothedStepRef.current
      smoothedStepRef.current += (stepRef.current - prevSmoothedStep) * dragSmoothFactor
      if (smoothedStepRef.current !== prevSmoothedStep) {
        timeline.seek(smoothedStepRef.current + 1, false)
      }
    }
    gsap.ticker.add(tick)
    return () => {
      gsap.ticker.remove(tick)
    }
  }, [fontReady, steps])

  return { refs, handleDrag }
}

const useStepSerializers = (refs) => useMemo(() => map(refs, ({ shapesRef }) => ({
  types: {
    brandElement: props => <BrandElements {...props} tweenRef={shapesRef} />
  }
})), [refs])

const Pillars = ({ className, slice }) => {
  const classes = useStyles()
  const { pillars } = slice
  const steps = useMemo(() => map(pillars, 'category'), [pillars])
  const { refs, handleDrag } = useTimeline(steps.length)
  const stepSerializers = useStepSerializers(refs)
  return (
    <section className={cn(className, classes.container)}>
      <header>
        <Timeline className={classes.timeline} steps={steps} onDrag={handleDrag} />
      </header>
      <div className={classes.content}>
        {map(pillars, ({ id, title, copy, image1, image2 }, i) => {
          const { containerRef, titleRef, copyRef, image1Ref, image2Ref } = refs[i]
          const serializers = stepSerializers[i]
          return (
            <article className={classes.pillar} key={id} ref={containerRef}>
              <div className={classes.pillarContent}>
                <h2 className={classes.pillarTitle} ref={titleRef}>{title}</h2>
                {copy && <RichText className={classes.copy} content={copy} ref={copyRef} serializers={serializers} />}
              </div>
              {image1 && <ResponsiveImage className={classes.image1} {...image1} ref={image1Ref} />}
              {image2 && <ResponsiveImage className={classes.image2} {...image2} ref={image2Ref} />}
            </article>
          )
        })}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
    ...sliceMarginStyles
  },
  timeline: {
    marginBottom: span(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: span(1, 'md')
    }
  },
  content: {
    display: 'flex',
    '& > $pillar:not(:first-child)': {
      visibility: 'hidden',
      marginLeft: span(-14),
      [theme.breakpoints.up('md')]: {
        marginLeft: span(-23, 'md')
      }
    }
  },
  pillar: {
    width: span(14),
    padding: [0, span(1)],
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      display: 'flex',
      width: span(23, 'md'),
      padding: [0, span(1, 'md'), 0, span(2, 'md')]
    }
  },
  pillarContent: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: span(5, 'md'),
      marginTop: span(1, 'md'),
      marginRight: span(2, 'md')
    }
  },
  pillarTitle: {
    width: span(7),
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  image1: {
    width: span(9),
    marginTop: span(2),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      flexShrink: 0,
      width: span(8, 'md')
    },
    '$pillar:nth-child(2n) > &': {
      marginLeft: span(3),
      [theme.breakpoints.up('md')]: {
        marginLeft: span(5, 'md')
      }
    }
  },
  image2: {
    width: span(7),
    marginLeft: span(5),
    marginTop: span(-(9 * 0.85) / (9 / 13)),
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: span(6, 'md'),
      margin: 0,
      left: span(16, 'md'),
      top: span((8 * 0.15) / (9 / 13), 'md')
    },
    '$pillar:nth-child(2n) > &': {
      marginLeft: 0,
      [theme.breakpoints.up('md')]: {
        left: span(9, 'md')
      }
    }
  }
}, { name: 'Pillars' })

export default Pillars
