import get from 'lodash/get'
import { combineReducers } from 'redux'
import {
  CONTENT_TYPE,
  GLOBAL_CONTENT_TYPE,
  REQUEST_PREVIEW_SESSION,
  SERVER_ERROR_TYPE,
  REHYDRATED
} from '../actions'
import makeFetchReducer from './makeFetchReducer'
import { ActionType } from 'redux-promise-middleware'

function makePageReducer () {
  const fetchReducer = makeFetchReducer(CONTENT_TYPE)
  return (state, action) => {
    if (action.type === `${CONTENT_TYPE}_${ActionType.Fulfilled}` && get(action, ['meta', 'arrivedTooLate'])) {
      // The race middleware is telling us to ignore this payload because another request is already in progress.
      // No state change - stay in busy state.
      return state
    }
    state = fetchReducer(state, action)
    switch (action.type) {
      case `${SERVER_ERROR_TYPE}_${ActionType.Fulfilled}`:
        return {
          ...state,
          busy: false,
          error: action.payload.error,
          content: action.payload.content
        }
      case `${SERVER_ERROR_TYPE}_${ActionType.Rejected}`:
        return {
          ...state,
          busy: false,
          error: action.payload,
          content: {
            type: 'errorPage',
            title: 'Server Error'
          }
        }
      default:
        return state
    }
  }
}

function makePreviewReducer () {
  return (state = false, action) => {
    switch (action.type) {
      case REQUEST_PREVIEW_SESSION:
        return true
      default:
        return state
    }
  }
}

export default combineReducers({
  page: makePageReducer(),
  global: makeFetchReducer(GLOBAL_CONTENT_TYPE),
  preview: makePreviewReducer(),
  rehydrated: (state = false, action) => {
    switch (action.type) {
      case REHYDRATED:
        return true
    }
    return state
  }
})
