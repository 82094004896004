import * as breakpoints from './breakpoints'
import * as fonts from './fonts'
import color from 'color'
import { vw } from './vw'
import detectIt from 'detect-it'

const parallax = {
  ratio: 0.8,
  scale: 1.2
}

const colors = {
  primary: '#00403d',
  secondary: '#d3817a',
  tertiary: '#ffe085',
  black: '#231f20',
  text: '#231f20',
  white: '#fff',
  beige: '#eee',
  footer: '#dddad7',
  button: '#005955'
}

const grid = {
  values: {
    min: 14,
    md: 23
  }
}

const headingsSelector = 'h1,h2,h3,h4,h5,h6'
const globalStyles = {
  '@font-face': fonts.faces,
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent'
  },
  ':root': {
    '--scrollbar-width': '0px'
  },
  html: {
    lineHeight: 1.4
  },
  body: {
    ...(
      process.env.NODE_ENV === 'development'
        ? require('./grid').generateGridStyles(grid.values)
        : undefined
    ),
    backgroundColor: colors.white,
    color: colors.text,
    fontFamily: fonts.body,
    fontWeight: fonts.bodyFontWeight,
    fontSize: 18,
    letterSpacing: '0.01em',
    '-webkitFontSmoothing': 'antialiased',
    '-mozOsxFontSmoothing': 'grayscale',
    [breakpoints.up('lg')]: {
      fontSize: vw(18, 'desktop')
    }
  },
  [headingsSelector]: {
    fontFamily: fonts.headings,
    fontWeight: fonts.headingsFontWeight,
    textTransform: 'uppercase',
    lineHeight: 0.8,
    margin: [0, 0, '0.6em']
  },
  h1: {
    fontSize: vw(90),
    letterSpacing: '-0.02em',
    [breakpoints.up('md')]: {
      fontSize: vw(182, 'desktop')
    }
  },
  h2: {
    fontSize: vw(42),
    letterSpacing: '0.005em',
    lineHeight: 0.9,
    [breakpoints.up('md')]: {
      lineHeight: 0.8,
      letterSpacing: '0.01em',
      fontSize: vw(72, 'desktop')
    }
  },
  h3: {
    fontSize: vw(42),
    letterSpacing: '0.01em',
    lineHeight: 0.9,
    [breakpoints.up('md')]: {
      lineHeight: 0.8,
      letterSpacing: '0.02em',
      fontSize: vw(56, 'desktop')
    }
  },
  p: {
    margin: [0, 0, '1.4em']
  },
  '::placeholder': {
    color: colors.primary,
    opacity: 0.6
  },
  '::selection': {
    backgroundColor: color(colors.secondary).lighten(0.35).hex()
  },
  button: {
    userSelect: 'none',
    cursor: 'pointer',
    appearance: 'none',
    outline: 'none',
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0
  },
  '.srOnly': {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    clipPath: 'inset(50%) !important',
    height: '1px !important',
    margin: '-1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute !important',
    width: '1px !important',
    whiteSpace: 'nowrap !important'
  },
  a: {
    color: 'currentColor',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  // For modern browsers
  // 1. The space content is one way to avoid an Opera bug when the
  //    contenteditable attribute is included anywhere else in the document.
  //    Otherwise it causes space to appear at the top and bottom of elements
  //    that are clearfixed.
  // 2. The use of `table` rather than `block` is only necessary if using
  //    `:before` to contain the top-margins of child elements.
  '.clearFix': {
    '&::before,&::after': {
      content: '" "', // 1
      display: 'table' // 2
    },
    '&::after': {
      clear: 'both'
    }
  }
}

export default {
  headingsSelector,
  global: globalStyles,
  breakpoints,
  grid,
  colors,
  fonts,
  parallax,
  zIndex: {
    // Keep all zIndexes here in one place so it’s easy to see what order things are in
    toolbar: 99,
    nav: 100,
    popup: 101,
    pageLoadingBar: 102
  },
  scrollbarWidthVar: 'var(--scrollbar-width)',
  link: (color, height = 1, offset = 0) => ({
    ...(detectIt.primaryInput === 'touch' ? {} : {
      overflow: 'hidden',
      position: 'relative',
      '&:before, &:after': {
        transition: 'transform 0.2s ease-in-out',
        content: '""',
        position: 'absolute',
        bottom: offset,
        left: '0',
        right: '0',
        height: height,
        backgroundColor: color
      },
      '&:before': {
        opacity: 0,
        transform: 'translateX(-100%)'
      },
      '&:after': {
        opacity: 1,
        transform: 'translateX(100%)'
      },
      '&:hover, &.selected': {
        '&:after': {
          transform: 'translateX(0%)',
          opacity: 0
        },
        '&:before': {
          transform: 'translateX(0)',
          opacity: 1
        }
      }
    })
  })
}
