import React, { useMemo, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import map from 'lodash/map'
import BlockContent from '@sanity/block-content-to-react'
import merge from 'lodash/merge'
import Link from '../Link'
import { resolveLink } from '../../helpers/resolveLink'
import theme from '../../style/theme'
import Button from '../Button'
import { vw } from '../../style/vw'
import BrandElements from './BrandElements'
import LogoLinks from './LogoLinks'

const serializers = {
  marks: {
    link: ({ mark, children }) => (
      <Link to={mark.href} target={mark.open_in_new_window ? '_blank' : undefined}>{children}</Link>
    ),
    internal_link: ({ mark, children }) => (
      <Link link={resolveLink(mark)}>{children}</Link>
    )
  },
  types: {
    brandElement: BrandElements,
    button: props => {
      const link = get(props, ['node', 'link', 0])
      return <Button link={resolveLink(link)} className='button' />
    },
    logoLinks: LogoLinks
  }
}

const RichText = forwardRef(({ className, content, tag = 'div', serializers: extraSerializers, children }, ref) => {
  const classes = useStyles()
  const Component = tag
  const allSerializers = useMemo(
    () => merge({ container: tag }, serializers, extraSerializers || {}),
    [tag, extraSerializers]
  )
  const blocks = useMemo(() => map(get(content, ['text'], content), block => ({
    _type: block.type, // BlockContent expects `_type` prop, not `type`.
    ...block
  })))
  return (
    <Component className={cn(className, classes.container)} ref={ref}>
      <BlockContent className={classes.blockContent} blocks={blocks} serializers={allSerializers} />
      {children}
    </Component>
  )
})

const useStyles = createUseStyles({
  blockContent: {
    '& > *': {
      marginBottom: vw(24),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(24, 'desktop')
      }
    },
    '& a': {
      color: 'currentColor',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    '& h1, & h2, & h3': {
      marginBottom: vw(32),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(50, 'desktop')
      }
    },
    '& > *:last-child': {
      marginBottom: 0
    },
    '& p, & ul': {
      lineHeight: 1.5,
      fontSize: vw(18),
      marginTop: 0,
      marginBottom: '1.5em',
      [theme.breakpoints.up('md')]: {
        fontSize: vw(18, 'desktop')
      },
      '& a': {
        color: 'currentColor',
        '&:hover': {
          color: theme.colors.secondary
        }
      }
    },
    '& ul': {
      paddingLeft: '1em'
    },
    '& ul > li': {
      marginBottom: '0.8em',
      fontSize: vw(18),
      lineHeight: 1.5,
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        fontSize: vw(18, 'desktop')
      }
    },
    '& blockquote': {
      margin: [0, 0, vw(24), 0],
      fontSize: vw(32),
      [theme.breakpoints.up('md')]: {
        margin: [0, 0, vw(24, 'desktop'), 0],
        fontSize: vw(32, 'desktop')
      }
    },
    '& .button': {
      color: theme.colors.white,
      textDecoration: 'none',
      '&:hover': {
        color: theme.colors.primary
      }
    }
  }
}, { name: 'RichText' })

export default RichText
