import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import map from 'lodash/map'
import range from 'lodash/range'
import fromPairs from 'lodash/fromPairs'
import flatten from 'lodash/flatten'
import cn from 'classnames'
import theme from '../style/theme'
import { getSocialLinks } from '../redux/selectors'
import { vw } from '../style/vw'
import { span } from '../style/span'
import Link from './Link'
import SocialLink from './SocialLink'
import { resolveLink } from '../helpers/resolveLink'
import round from '../helpers/round'

const processLink = (link) => {
  const result = { Component: Link }
  if (link) {
    result.link = resolveLink({
      text: link.text || link.title,
      ...link
    })
    if (result.link.text) {
      if (!/\s/.test(result.link.text)) {
        result.lacksWhitespace = true
      }
      if (/, .+, /.test(result.link.text)) {
        const lines = result.link.text.split(', ')
        result.link.text = flatten(map(lines, (line, i) =>
          i < lines.length - 1
            ? [line, <span key={i}>, </span>]
            : line
        ))
      }
    }
  }
  return result
}

const processSocialLink = (link) => {
  const result = processLink(link)
  result.Component = SocialLink
  return result
}

function LinkGroups ({ className, classNames = {}, socialTitle, links, socialLinksFirst }) {
  const socialLinks = useSelector(getSocialLinks)
  const classes = useStyles({ groupsCount: links.length + 1 })
  const linkGroups = useMemo(() => {
    const items = map(links, ({ submenuLinks, ...rest }) => ({
      ...rest,
      links: map(submenuLinks, processLink)
    }))
    const social = {
      title: socialTitle,
      links: map(socialLinks, processSocialLink),
      social: true
    }
    socialLinksFirst ? items.unshift(social) : items.push(social)
    return items
  }, [links, socialLinks, socialLinksFirst])
  return (
    <ul className={cn(classes.linkGroups, className)}>
      {map(linkGroups, ({ title, social, links }, i) => (
        <li className={cn(classes.linkGroup, classes.linkGroupStatic, classNames.linkGroup)} key={i}>
          <h3 className={cn(classes.linkGroupTitle, classNames.linkGroupTitle)}>{title}</h3>
          <ul className={cn(classes.linkItems, social && classes.socialLinkItems)}>
            {map(links, ({ Component, link, lacksWhitespace }, j) => (
              <li
                className={cn(
                  classes.linkItem,
                  classNames.linkItem,
                  social && classes.socialLinkItem
                )}
                key={j}
              >
                <Component
                  className={cn(
                    classes.link,
                    classNames.link,
                    lacksWhitespace && classes.singleLineLink,
                    !social && cn(classes.textLink, classNames.textLink)
                  )}
                  link={link}
                />
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

const useStyles = createUseStyles({
  linkGroups: {
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    listStyleType: 'none',
    margin: [0, span(-0.5)],
    padding: 0,
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      margin: [0, span(-0.5, 'md')]
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  linkGroup: ({ groupsCount }) => {
    // We still want to use flex-direction:row so the vertical tops are aligned,
    // but we want the items ordered vertically.
    const evenGroupsCount = groupsCount + (groupsCount % 2)
    return {
      // composes: ['$linkGroupStatic'], // Composes is bad in dynamic styles, it keeps creating new styles on every render
      ...fromPairs(map(range(groupsCount), n => [
        `&:nth-child(${n + 1})`,
        {
          order: (n < evenGroupsCount / 2)
            ? (n * 2)
            : (n * 2 - (evenGroupsCount - 1))
        }
      ]))
    }
  },
  linkGroupStatic: {
    display: 'block',
    width: span(5),
    margin: [0, span(0.5), span(2)],
    padding: 0,
    [theme.breakpoints.up('md')]: {
      // Need to unset the `order`, but this rule has lower precedence than .linkGroup:nth-child
      // because dynamic rules come later in source order. So even using a nth-child selector here
      // does not boost precedence enough. Important will do.
      order: 'unset !important',
      width: span(4, 'md'),
      margin: [0, span(0.5, 'md'), span(2, 'md')]
    },
    '&:last-child': {
      [theme.breakpoints.up('md')]: {
        marginRight: `calc(${span(0.5, 'md')} - 1px)`
      }
    }
  },
  linkGroupTitle: {
    fontSize: 14,
    letterSpacing: '0.04em',
    lineHeight: 1,
    marginBottom: '1.5em',
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
      letterSpacing: '0.04em',
      lineHeight: 1
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  linkItems: {
    display: 'block',
    listStyleType: 'none',
    margin: 0,
    padding: 0
  },
  linkItem: {
    display: 'block',
    margin: 0,
    padding: 0
  },
  link: {
    display: 'inline-block',
    color: 'inherit',
    textDecoration: 'none',
    [theme.breakpoints.up('fab')]: {
      '& > span': {
        display: 'block',
        width: 0,
        height: 0,
        overflow: 'hidden'
      }
    },
    overflow: 'hidden',
    position: 'relative',
    ...theme.link(theme.colors.secondary, 2)
  },
  textLink: {
    padding: ['0.53em', 0]
  },
  singleLineLink: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  socialLinkItems: {
    margin: [
      `${round((7.5 - 10) / 14)}em`,
      `${round(-30 / 14)}em`,
      `${round((7.5 - 10) / 14)}em`,
      `${round(-10 / 14)}em`
    ],
    [theme.breakpoints.up('fab')]: {
      marginRight: `${round(-10 / 14)}em`
    },
    '& a:before, & a:after': {
      display: 'none'
    }
  },
  socialLinkItem: {
    display: 'inline-block'
  }
}, { name: 'LinkGroups' })

export default LinkGroups
