import React from 'react'
import { useInView } from 'react-intersection-observer'
import composeRefs from '../helpers/composeRefs'
import useReversingLottieAnimation from '../hooks/useReversingLottieAnimation'

const InViewShapes = ({ className, animationData, ...rest }) => {
  const [inViewRef, inView] = useInView({ threshold: 1 })
  const animationRef = useReversingLottieAnimation(animationData, inView)
  const ref = composeRefs(inViewRef, animationRef)

  return (
    <div className={className} ref={ref} {...rest} />
  )
}

export default InViewShapes
