import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import map from 'lodash/map'
import each from 'lodash/each'
import filter from 'lodash/filter'
import get from 'lodash/get'
import last from 'lodash/last'
import isEmpty from 'lodash/isEmpty'
import cn from 'classnames'

import BackgroundColor from './Slices/BackgroundColor'
import Hero from './Slices/Hero'
import RichText from './Slices/RichText'
import ImageSlice from './Slices/ImageSlice'
import PullQuote from './Slices/PullQuote'
import RichTextWithImage, { IMAGE_ASPECT as RICH_TEXT_WITH_IMAGE_ASPECT } from './Slices/RichTextWithImage'
import ProjectHero from './Slices/ProjectHero'
import Testimonials from './Slices/Testimonials'
import BrandLogos from './Slices/BrandLogos'
import AmbitionsCarousel from './Slices/AmbitionsCarousel'
import Bios from './Slices/Bios'
import InitiativesTitle from './Slices/InitiativesTitle'

import ErrorBoundary from './ErrorBoundary'
import theme from '../style/theme'
import { span, sliceMarginStyles } from '../style/span'

const sliceComponentSelector = {
  hero_slice: Hero,
  rich_text: RichText,
  image_slice: ImageSlice,
  pull_quote: PullQuote,
  rich_text_and_image: RichTextWithImage,
  project_hero: ProjectHero,
  testimonials: Testimonials,
  brand_logos: BrandLogos,
  ambitions_carousel: AmbitionsCarousel,
  bios: Bios,
  initiatives_title: InitiativesTitle
}

function groupSlices (slices) {
  const rawGroups = []
  let currentGroup
  each(slices, (slice, sliceIndex) => {
    if (slice.type === 'change_background_color' || !currentGroup) {
      let backgroundColor
      if (slice.type === 'change_background_color' && slice.backgroundColor !== theme.colors.white) {
        backgroundColor = slice.backgroundColor
      }
      if (!currentGroup || backgroundColor !== currentGroup.backgroundColor) {
        currentGroup = { slices: [], sliceTypes: [], backgroundColor }
        rawGroups.push(currentGroup)
      }
    }
    const Component = sliceComponentSelector[slice.type]
    if (Component) {
      currentGroup.slices.push(
        <ErrorBoundary key={`slice-${sliceIndex}`}>
          <Component slice={slice} />
        </ErrorBoundary>
      )
      currentGroup.sliceTypes.push(slice)
    }
  })

  const nonEmptyGroups = filter(rawGroups, ({ slices }) => slices.length > 0)

  // If we filtered out some groups, there may now be two groups in a row, with the same color,
  // that should be merged into a single group.
  const groups = []
  each(nonEmptyGroups, (group) => {
    const prevGroup = groups[groups.length - 1]
    if (prevGroup && prevGroup.backgroundColor === group.backgroundColor) {
      // Merge the groups
      prevGroup.slices = [
        ...prevGroup.slices,
        group.slices
      ]
    } else {
      groups.push(group)
    }
  })

  return groups
}

const getBackgroundClasses = (previousSlice, lastSlice, classes) => {
  if (previousSlice && lastSlice) {
    const isRichTextWithImage = (slice) => slice.type === 'rich_text_and_image'

    const pullTopUp = isRichTextWithImage(previousSlice)
    const pullTopUpMobile = isRichTextWithImage(previousSlice) && previousSlice.image_mobile_order === 'bottom'
    const pullBottomUp = isRichTextWithImage(lastSlice)
    const pullBottomUpMobile = isRichTextWithImage(lastSlice) && lastSlice.image_mobile_order === 'bottom'

    return cn(
      pullTopUp && classes.pullTopUp,
      pullTopUpMobile && classes.pullTopUpMobile,
      pullBottomUp && classes.pullBottomUp,
      pullBottomUpMobile && classes.pullBottomUpMobile
    )
  }
}

const Slices = ({ slices }) => {
  const classes = useStyles()
  return useMemo(() => {
    const groups = groupSlices(slices)
    return map(groups, ({ backgroundColor, slices, sliceTypes }, groupIndex) => {
      if (!backgroundColor) {
        return slices
      }
      const previousSlice = last(get(groups, [groupIndex - 1, 'sliceTypes']))
      const lastSlice = last(sliceTypes)
      const backgroundClasses = getBackgroundClasses(previousSlice, lastSlice, classes)

      return (
        <BackgroundColor
          key={`group-${groupIndex}`}
          backgroundColor={backgroundColor}
          isFirstSlice={groupIndex === 0}
          isLastSlice={groupIndex === groups.length - 1}
          className={backgroundClasses}
          contours={groupIndex !== 0} // this is here for the error pages, they only have a single slice so the contours will conflict with the contours in the footer
        >
          {React.Children.map(slices, child => React.cloneElement(
            child, {}, isEmpty(child.props.children)
              ? null
              : React.cloneElement(React.Children.only(child.props.children), { lastSliceInBackground: last(slices) === child })
          ))}
        </BackgroundColor>
      )
    })
  }, [slices])
}

const backgroundImageOffset = 1 / RICH_TEXT_WITH_IMAGE_ASPECT
const sliceMarginStylesMarginBottom = sliceMarginStyles.marginBottom
const sliceMarginStylesMarginBottomDesktop = sliceMarginStyles[theme.breakpoints.up('md')].marginBottom
const offset = 0.15

const useStyles = createUseStyles({
  pullTopUpMobile: {
    marginTop: `calc(((${span(12)} * ${backgroundImageOffset}) * -${offset}) - ${sliceMarginStylesMarginBottom})`,
    paddingTop: `calc(((${span(12)} * ${backgroundImageOffset}) * ${offset}) + ${sliceMarginStylesMarginBottom})`
  },
  pullTopUp: {
    [theme.breakpoints.up('md')]: {
      marginTop: `calc(((${span(9, 'md')} * ${backgroundImageOffset}) * -${offset}) - ${sliceMarginStylesMarginBottomDesktop})`,
      paddingTop: `calc(((${span(9, 'md')} * ${backgroundImageOffset}) * ${offset}) + ${sliceMarginStylesMarginBottomDesktop})`
    }
  },
  pullBottomUpMobile: {
    marginBottom: `calc(((${span(18)} * ${backgroundImageOffset}) * ${offset}) + ${sliceMarginStylesMarginBottom})`,
    '& > *:last-child': {
      marginBottom: `calc((${span(18)} * ${backgroundImageOffset}) * -${offset})`
    }
  },
  pullBottomUp: {
    [theme.breakpoints.up('md')]: {
      marginBottom: `calc(((${span(16, 'md')} * ${backgroundImageOffset}) * ${offset}) + ${sliceMarginStylesMarginBottomDesktop})`
    },
    '& > *:last-child': {
      [theme.breakpoints.up('md')]: {
        marginBottom: `calc((${span(16, 'md')} * ${backgroundImageOffset}) * -${offset})`
      }
    }
  }
})

export default Slices
