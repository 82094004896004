import React, { forwardRef, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import { span } from '../../style/span'
import { getMenuItems, getNavColors } from '../../redux/selectors'
import { resolveLink } from '../../helpers/resolveLink'
import Link from '../Link'
import Hamburger from './Hamburger'
import AnimatedLogo from './AnimatedLogo'
import { navMount, navUnmount } from '../../redux/actions'
import Menu from './Menu'

const Nav = forwardRef((props, ref) => {
  // Color is changed in the nav middleware when we receive a transition end action
  const { color, highlightColor } = useSelector(getNavColors)
  const classes = useStyles({ color, highlightColor })
  const menuItems = useSelector(getMenuItems)
  const hamburgerRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(navMount({
      navId: 'nav',
      hamburgerId: 'hamburger',
      menuId: 'menu',
      circleId: 'circle'
    }))
    return () => {
      dispatch(navUnmount())
    }
  }, [])

  return (
    <header className={classes.header} ref={ref}>
      <div id='nav' className={classes.wrapper}>
        <Link className={classes.logoLink} to='/'>
          <AnimatedLogo color={color} />
        </Link>
        <nav className={classes.nav}>
          {menuItems && (
            <ul className={classes.list}>
              {menuItems.map(item => (
                <li key={item.text}>
                  <Link className={cn(classes.link, classes.highlightColor, { selected: item.selected })} link={resolveLink(item)} />
                </li>
              ))}
            </ul>
          )}
        </nav>
      </div>
      <div className={classes.circle} id='circle' />
      <Menu id='menu' />
      <Hamburger className={classes.hamburger} ref={hamburgerRef} id='hamburger' />
    </header>
  )
})

const useStyles = createUseStyles({
  header: {
    zIndex: theme.zIndex.nav,
    top: 0,
    position: 'absolute',
    width: '100%',
    color: ({ color }) => color
  },
  wrapper: {
    boxSizing: 'content-box',
    display: 'flex',
    left: 0,
    right: 0,
    height: vw(45),
    padding: span(1),
    paddingBottom: 0,
    [theme.breakpoints.up('md')]: {
      height: vw(45, 'desktop'),
      padding: span(0.65, 'md'),
      paddingBottom: 0
    }
  },
  nav: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center'
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    '& > li:not(:first-child)': {
      marginLeft: vw(70),
      [theme.breakpoints.up('md')]: {
        marginLeft: vw(70, 'desktop')
      }
    }
  },
  highlightColor: ({ highlightColor }) => ({
    '&:after, &:before': {
      backgroundColor: highlightColor
    }
  }),
  link: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: vw(12),
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      fontSize: 12,
      height: 45
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: vw(12, 'desktop'),
      height: vw(45, 'desktop')
    },
    ...theme.link(undefined, 2, '0.4em')
  },
  hamburger: {
    position: 'fixed',
    top: span(1),
    right: span(1),
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      top: span(0.65, 'md'),
      right: span(0.65, 'md')
    }
  },
  circle: {
    extend: 'hamburger',
    pointerEvents: 'none',
    position: 'fixed',
    borderRadius: '50%',
    backgroundColor: theme.colors.secondary,
    width: vw(45),
    height: vw(45),
    minHeight: 42,
    minWidth: 42,
    [theme.breakpoints.up('md')]: {
      width: vw(45, 'desktop'),
      height: vw(45, 'desktop')
    }
  }
}, { name: 'Nav' })

export default Nav
