import React, { Suspense, lazy, useState, useMemo } from 'react'
import inDOM from 'dom-helpers/canUseDOM'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../redux/selectors'
import theme from '../../style/theme'
import Color from 'color'

const ContoursShader = lazy(() => import(/* webpackChunkName: "contours" */ './ContoursShader'))

const contourProps = {
  mobile: {
    steps: 11,
    lineWidth: [0.0018, 0.0018],
    scale: 0.35,
    speed: 0.018
  },
  default: {
    steps: 15,
    lineWidth: [0.0012, 0.0012],
    scale: 0.6,
    speed: 0.009
  }
}

const defaultLineColor = { color: theme.colors.white, opacity: 0.2 }

const lineColors = {
  '#00403d': { color: '#0b4946', opacity: 1.0 },
  '#d3817a': { color: '#d68983', opacity: 1.0 },
  '#c3bbb7': { color: '#c7bfbb', opacity: 1.0 },
  '#fff0e7': { color: '#fff6f0', opacity: 1.0 },
  '#d8d8d8': defaultLineColor,
  '#cee0d5': { color: '#d5e4db', opacity: 1.0 },
  default: defaultLineColor
}

const LazyContoursShader = ({ backgroundColor = theme.colors.white, ...props }) => {
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const [seed] = useState(Math.round(Math.random() * 1000))
  const lineColor = useMemo(() => {
    const lc = lineColors[backgroundColor] || lineColors.default
    return {
      color: [
        // unit array will take the alpha out if the opacity is 1
        ...Color(lc.color).alpha(lc.opacity).unitArray(),
        1.0
      ].slice(0, 4),
      backgroundColor: Color(backgroundColor).unitArray()
    }
  }, [backgroundColor])
  return inDOM ? (
    <Suspense fallback={null}>
      <ContoursShader seed={seed} {...lineColor} {...contourProps[isMobile ? 'mobile' : 'default']} {...props} />
    </Suspense>
  ) : null
}

export default LazyContoursShader
