import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import map from 'lodash/map'
import ResponsiveImage from '../ResponsiveImage'
import { sliceMarginStyles, span } from '../../style/span'
import theme from '../../style/theme'
import RichText from '../RichText'
import Link from '../Link'
import { vw } from '../../style/vw'

const BrandLogos = ({ className, slice }) => {
  const classes = useStyles()
  const { copy, title, logos } = slice
  return (
    <section className={cn(className, classes.brandLogos)}>
      <div className={classes.titleAndCopy}>
        {title && <h3 className={classes.title}>{title}</h3>}
        {copy && <RichText className={classes.richText} content={copy} />}
      </div>
      <div className={classes.logos}>
        {map(logos, ({ image, url }, i) => (
          <Link key={i} to={url} target='_blank' nonLinkTag='span' className={classes.link}>
            <ResponsiveImage className={classes.logo} {...image} />
          </Link>
        ))}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  brandLogos: {
    extend: [sliceMarginStyles],
    margin: [0, 'auto'],
    width: span(12),
    [theme.breakpoints.up('md')]: {
      width: span(19, 'md'),
      display: 'flex',
      alignItems: 'flex-start'
    }
  },
  titleAndCopy: {
    marginBottom: span(2),
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: span(7, 'md'),
      marginRight: span(3, 'md'),
      marginBottom: 0
    }
  },
  title: {
    [theme.breakpoints.up('md')]: {
      width: span(6, 'md'),
      fontSize: vw(90, 'desktop'),
      marginBottom: vw(90, 'desktop')
    }
  },
  logos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: span(8, 'md')
    }
  },
  link: {
    display: 'block',
    width: span(5),
    marginBottom: span(2),
    transition: 'opacity 0.15s ease-in-out',
    [theme.breakpoints.up('md')]: {
      width: span(3, 'md'),
      marginBottom: span(1.25, 'md')
    },
    '&:hover': {
      opacity: 0.6
    }
  }
}, { name: 'BrandLogos' })

export default BrandLogos
