import React, { useEffect, useRef, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import range from 'lodash/range'
import { span } from '../../../style/span'
import theme from '../../../style/theme'
import { vw } from '../../../style/vw'
import outlineText from '../../../style/outlineText'
import gsap from 'gsap'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../../redux/selectors'
import SmoothScroll from '../../SmoothScroll'

const TEXT_GUTTER_SPAN = 0.5
const TEXT_WIDTH_SPAN = 6
const TEXT_WIDTH_SPAN_MOBILE = 7

const Ticker = ({ className, titles }) => {
  const classes = useStyles()
  const ref = useRef()
  const tickerTimelineRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const tickerTitles = useMemo(() => {
    const spans = titles.length * (TEXT_WIDTH_SPAN + TEXT_GUTTER_SPAN)
    const repeats = Math.ceil(21 / spans) + 1
    return range(repeats).map(i => titles)
  }, [titles])

  useEffect(() => {
    const offset = -(gsap.getProperty(ref.current.children[0], 'width') / gsap.getProperty(ref.current, 'width')) * 100
    gsap.set(ref.current, { x: `${offset}%` })

    tickerTimelineRef.current = gsap.timeline({
      onRepeat: () => { tickerTimelineRef.current.invalidate() },
      repeat: -1
    })
    tickerTimelineRef.current.to(ref.current, {
      duration: isMobile ? 1 : 5,
      ease: 'none',
      x: '+=10%',
      modifiers: {
        x: gsap.utils.unitize((x, target) => {
          const newValue = parseFloat(x)
          return newValue >= 0 ? offset + newValue : newValue
        })
      }
    })
    return () => {
      tickerTimelineRef.current.kill()
      tickerTimelineRef.current = null
    }
  }, [tickerTitles, isMobile])

  // Animates the ticker speed based on the smooth scroll velocity
  useEffect(() => {
    const tick = () => {
      if (tickerTimelineRef.current) {
        const speed = gsap.utils.interpolate(1, 4, Math.abs(SmoothScroll.velocity) / 40)
        tickerTimelineRef.current.timeScale(speed)
      }
    }
    gsap.ticker.add(tick)
    return () => {
      gsap.ticker.remove(tick)
    }
  }, [])

  return (
    <div className={cn(classes.ticker, className)} ref={ref}>
      {tickerTitles && tickerTitles.map((titles, i) => (
        <span key={i} className={classes.item}>
          {titles.map((title, j) => (
            <span className={classes.title} key={j}>{title}</span>
          ))}
        </span>
      ))}
    </div>
  )
}

const useStyles = createUseStyles({
  ticker: {
    display: 'flex'
  },
  item: {
    flexShrink: 0,
    display: 'flex'
  },
  title: {
    flexShrink: 0,
    whiteSpace: 'normal',
    width: span(TEXT_WIDTH_SPAN_MOBILE),
    fontFamily: theme.fonts.headings,
    fontSize: vw(60),
    textTransform: 'uppercase',
    lineHeight: 0.8,
    marginRight: span(TEXT_GUTTER_SPAN),
    color: theme.colors.white, // TODO: Detect the color based on the background color
    ...outlineText(theme.colors.white),
    [theme.breakpoints.up('md')]: {
      marginRight: span(TEXT_GUTTER_SPAN, 'md'),
      width: span(TEXT_WIDTH_SPAN, 'md'),
      fontSize: vw(120, 'desktop')
    },
    '&:nth-child(even)': {
      color: 'transparent'
    }
  }
}, { name: 'Ticker' })

export default Ticker
