import get from 'lodash/get'
import React from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import round from '../helpers/round'
import defaultImage from '../images/spacer'
import { quart } from '../style/eases'
import ParallaxElement from './ParallaxElement'

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    '& picture::before': {
      display: 'block',
      content: ({ aspect }) => aspect ? '""' : undefined,
      paddingTop: ({ aspect }) => aspect ? `${round(100 / aspect)}%` : undefined
    }
  },
  image: {
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: `opacity 0.5s ${quart.out}`,
    willChange: 'opacity',
    objectFit: 'cover',
    fontFamily: '"object-fit: cover;"', // object-fit polyfill
    '&.lazyloaded': {
      opacity: 1
    }
  },
  link: {
    textDecoration: 'none'
  },
  parallax: {
    height: '100%'
  }
}, { name: 'ResponsiveImage' })

const Picture = ({ classes, alt, sizes, disableLazy }) => {
  const hasWebp = !!get(sizes, [0, 'webpUrl'])
  const srcset = key => sizes.map(item => (`${item[key]} ${item.width}w`)).join()
  const srcName = disableLazy ? 'srcSet' : 'data-srcset'
  return (
    <picture>
      {hasWebp && <source {...{ [srcName]: srcset('webpUrl') }} type='image/webp' />}
      {sizes && <source {...{ [srcName]: srcset('url') }} />}
      <img
        data-sizes='auto'
        alt={alt}
        className={cn((sizes && !disableLazy && 'lazyload') || (disableLazy && 'lazyloaded'), classes.image)}
        src={defaultImage}
      />
    </picture>
  )
}

const NoScript = ({ classes, alt, sizes }) => {
  if (sizes) {
    return (
      <noscript>
        <img
          src={sizes[sizes.length - 1].url}
          srcSet={sizes.map(item => (`${item.url} ${item.width}w`)).join()}
          className={`${classes.image} lazyloaded`}
          alt={alt}
        />
      </noscript>
    )
  }
  return null
}

const ResponsiveImage = React.forwardRef(function ResponsiveImage ({ disableLazy, aspect, children, className, alt, sizes, palette, style, parallax }, ref) {
  const classes = useStyles({ aspect })
  return (
    <div className={cn(classes.container, className)} ref={ref} style={style}>
      {parallax ? (
        <ParallaxElement {...parallax} className={cn(classes.parallax, 'picture-wrapper')}>
          <Picture classes={classes} alt={alt} sizes={sizes} disableLazy={disableLazy} />
        </ParallaxElement>
      ) : (
        <Picture classes={classes} alt={alt} sizes={sizes} disableLazy={disableLazy} />
      )}
      <NoScript classes={classes} alt={alt} sizes={sizes} />
      {children}
    </div>
  )
})

export default ResponsiveImage
