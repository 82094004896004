import inDOM from 'dom-helpers/canUseDOM'
import { createStore, applyMiddleware, compose as defaultCompose, combineReducers } from 'redux'
import { connectRoutes } from 'redux-first-router'
import reducers from './reducers'
import contentMiddleware from './middlewares/content'
import layoutMiddleware from './middlewares/layout'
import googleTagsMiddleware from './middlewares/googleTags'
import ignoreScrollDuringTransitionMiddleware, { getStateStorage } from './middlewares/ignoreScrollDuringTransition'
import scrollMiddleware from './middlewares/scroll'
import rejectedPromiseMiddleware from './middlewares/rejectedPromise'
import navMiddleware from './middlewares/nav'
import promiseMiddleware from 'redux-promise-middleware'
import { loadingBarMiddleware } from 'react-redux-loading-bar'
import routesMap from './routesMap'
import querystring from 'query-string'

export default function ({ preloadedState, initialEntries } = {}) {
  let compose = defaultCompose
  /* global __REDUX_DEVTOOLS_EXTENSION_COMPOSE__:false */
  if (typeof __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
    // if you install the Redux Devtools extension (see https://github.com/zalmoxisus/redux-devtools-extension)
    // then this will connect to the dev tools and allow you to inspect Redux state
    compose = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  }

  let restoreScroll
  if (inDOM) {
    restoreScroll = require('redux-first-router-restore-scroll').default({
      // We’ll restore the scroll position as part of the transitions in the Page component.
      manual: true,
      stateStorage: getStateStorage()
    })
  }
  const {
    reducer: routerReducer,
    middleware: routerMiddleware,
    enhancer: routerEnhancer,
    thunk: routerThunk
  } = connectRoutes(
    routesMap, {
      initialEntries,
      restoreScroll,
      querySerializer: querystring
    })

  const rootReducer = combineReducers({ ...reducers, location: routerReducer })
  const enhancers = compose(
    routerEnhancer,
    applyMiddleware(
      routerMiddleware,
      googleTagsMiddleware,
      contentMiddleware,
      layoutMiddleware,
      rejectedPromiseMiddleware,
      promiseMiddleware,
      ignoreScrollDuringTransitionMiddleware,
      scrollMiddleware,
      navMiddleware,
      loadingBarMiddleware()
    ))

  const store = createStore(rootReducer, preloadedState, enhancers)

  if (module.hot && process.env.NODE_ENV === 'development') {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      const rootReducer = combineReducers({ ...reducers, location: routerReducer })
      store.replaceReducer(rootReducer)
    })
  }

  return { store, routerThunk }
}
