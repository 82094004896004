export const CONTENT_TYPE = 'CONTENT'
export const GLOBAL_CONTENT_TYPE = 'GLOBAL_CONTENT'
export const REQUEST_PREVIEW_SESSION = 'REQUEST_PREVIEW_SESSION'
export const SERVER_ERROR_TYPE = 'SERVER_ERROR'

export const globalContentActionCreator = promise => ({
  type: GLOBAL_CONTENT_TYPE,
  payload: promise
})

export const contentActionCreator = promise => ({
  type: CONTENT_TYPE,
  meta: { scope: 'page' },
  payload: promise
})

export const requestPreviewSessionCreator = () => ({
  type: REQUEST_PREVIEW_SESSION
})

export const serverErrorActionCreator = (error) => ({
  type: SERVER_ERROR_TYPE,
  payload: error
})
