import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar'
import config from './config'
import content from './content'
import layout from './layout'

export default {
  config,
  content,
  layout,
  loadingBar
}
