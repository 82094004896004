import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import ResponsiveImage from '../ResponsiveImage'
import { sliceMarginStyles, span } from '../../style/span'
import theme from '../../style/theme'

const ImageSlice = forwardRef(({
  className,
  slice: { image }
}, ref) => {
  const classes = useStyles()
  return image
    ? (
      <section className={cn(className, classes.wrapper)} ref={ref}>
        <ResponsiveImage className={classes.image} {...image} />
      </section>
    ) : null
})

const useStyles = createUseStyles({
  wrapper: {
    extend: [sliceMarginStyles],
    margin: [0, 'auto'],
    width: span(12),
    [theme.breakpoints.up('md')]: {
      width: span(19, 'md')
    }
  }
}, { name: 'ImageSlice' })

export default ImageSlice
