/* eslint-env browser */
import compact from 'lodash/compact'
import last from 'lodash/last'
import merge from 'lodash/merge'
import defaultsDeep from 'lodash/defaultsDeep'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import {
  getContentApiUrl,
  getGlobalContentApiUrl,
  getCurrentQueryString,
  getPreviewApiUrl,
  isPreviewMode
} from './selectors'
import {
  contentActionCreator,
  globalContentActionCreator
} from './actions'

const toUrl = (components, query) => compact(components).join('/') + (isEmpty(query) ? '' : `?${queryString.stringify(query)}`)

/**
 * The last parameter can optionally be an options object. If a `qs` option is specified in the options object it will
 * be converted to the query string in the url
 */
export async function fetchJson (...components) {
  const options = {
    headers: {
      Accept: 'application/json'
    },
    credentials: 'omit'
  }
  if (typeof last(components) === 'object') {
    merge(options, components.pop())
  }

  const { qs, json, ...fetchOptions } = options
  if (json) {
    defaultsDeep(fetchOptions, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify(json)
    })
  }

  const response = await fetch(toUrl(components, qs), fetchOptions)
  const data = await response.json()

  if (!response.ok) {
    const error = new Error((data.error && data.error.message) || 'Failed to fetch content')
    error.status = response.status
    throw error
  }

  return data
}

export async function sendFetchPageContentRequest (dispatch, getState, {
  path, qs, actionCreator = contentActionCreator
} = {}) {
  const queryString = getCurrentQueryString(getState())
  const apiUrlSelector = isPreviewMode(getState()) ? getPreviewApiUrl : getContentApiUrl
  const components = [apiUrlSelector(getState())]
  const pathComponents = compact(path.split('/'))
  const options = { qs: { ...queryString, ...qs } }
  components.push(path === '/' ? 'home' : last(pathComponents))
  return dispatch(actionCreator(fetchJson(...components, options)))
}

export async function fetchPageContent (dispatch, getState, path, actionMeta) {
  return sendFetchPageContentRequest(dispatch, getState, {
    path,
    actionCreator: (promise) => merge(contentActionCreator(promise), { meta: actionMeta })
  })
}

export async function fetchGlobalContent (dispatch, getState) {
  const api = getGlobalContentApiUrl(getState())
  return dispatch(globalContentActionCreator(fetchJson(api)))
}

export async function fetchPageNotFoundContent (dispatch, getState) {
  const api = getGlobalContentApiUrl(getState())
  return dispatch(contentActionCreator(fetchJson(api, 'error-page-404')))
}
