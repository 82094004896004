import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import Link from './Link'

import { ReactComponent as InstagramIcon } from '../images/instagram.svg'
import { ReactComponent as FacebookIcon } from '../images/facebook.svg'
import { ReactComponent as TwitterIcon } from '../images/twitter.svg'
import { ReactComponent as LinkedInIcon } from '../images/linkedin.svg'
import round from '../helpers/round'

function getSocialIcon (url) {
  if (url.indexOf('facebook') >= 0) {
    return FacebookIcon
  }
  if (url.indexOf('twitter') >= 0) {
    return TwitterIcon
  }
  if (url.indexOf('instagram') >= 0) {
    return InstagramIcon
  }
  if (url.indexOf('linkedin') >= 0) {
    return LinkedInIcon
  }
}

const SocialLink = ({ className, link, url, text, ...rest }) => {
  const classes = useStyles()
  const Icon = getSocialIcon(url || get(link, ['url']))
  const srOnly = text || get(link, ['text'])
  return (
    <Link className={cn(classes.socialLink, className)} to={url} link={link} {...rest}>
      {srOnly && <span className='srOnly'>{srOnly}</span>}
      {Icon && <Icon className={classes.icon} />}
    </Link>
  )
}

const useStyles = createUseStyles({
  socialLink: {
    display: 'block',
    width: `${round(40 / 14)}em`,
    height: `${round(40 / 14)}em`,
    padding: '25%',
    position: 'relative',
    transition: 'opacity 0.15s ease-in-out',
    '&:hover': {
      opacity: 0.6
    }
  },
  icon: {
    display: 'block',
    width: '100%',
    height: '100%',
    fill: 'currentColor'
  }
}, { name: 'SocialLink' })

export default SocialLink
