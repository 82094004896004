import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import compact from 'lodash/compact'
import theme from '../../../style/theme'
import { vw } from '../../../style/vw'
import { span } from '../../../style/span'
import SplitWordsAnimation from '../../SplitWordsAnimation'
import ScrollHint from '../../ScrollHint'
import newlinesToBr from '../../../helpers/newlinesToBr'
import TinyCategoryTitle from '../TinyCategoryTitle'

const normalizeNewlines = text => text && text.replace(/\r?\n/g, '\r\n')

const splitLastWord = text => {
  const [, ...matches] = /([\s\S]*\s)(\S+)$/.exec(text) || []
  return matches || [text]
}

const Title = ({ className, category, title, large, scrollHint, tag = 'h1', screenReaderClone = true }) => {
  const classes = useStyles()
  let content = title
  if (scrollHint) {
    const [otherWords, lastWord] = splitLastWord(title)
    const scrollHint = <ScrollHint className={classes.scrollHint} variation='large' />
    content = (
      <>
        {newlinesToBr(otherWords || title)}
        {lastWord
          ? <span className={classes.noBreak}>{lastWord}{scrollHint}</span>
          : scrollHint}
      </>
    )
  }
  const srTitle = normalizeNewlines(compact([category, title]).join(': '))
  const Tag = tag

  return (
    <SplitWordsAnimation srOnlyContent={srTitle} screenReaderClone={screenReaderClone}>
      <Tag className={cn(classes.title, large && classes.largeTitle, className)}>
        {category && (
          <TinyCategoryTitle>
            <span className={classes.category}>{category}</span>
          </TinyCategoryTitle>
        )}
        {content}
      </Tag>
    </SplitWordsAnimation>
  )
}

const useStyles = createUseStyles({
  title: {
    fontFamily: theme.fonts.headings,
    fontWeight: theme.fonts.headingsFontWeight,
    textTransform: 'uppercase',
    lineHeight: 0.8,
    fontSize: vw(90),
    letterSpacing: '-0.02em',
    margin: [0, 0, '0.6em'],
    [theme.breakpoints.up('md')]: {
      fontSize: vw(182, 'desktop'),
      margin: [0, span(2, 'md'), '0.467em', span(3, 'md')]
    }
  },
  largeTitle: {
    fontSize: vw(100),
    [theme.breakpoints.up('xs')]: {
      fontSize: vw(223, 'desktop'),
      marginLeft: span(5, 'md')
    }
  },
  noBreak: {
    display: 'inline-block',
    whiteSpace: 'nowrap'
  },
  scrollHint: {
    display: 'inline-block',
    width: '0.72em',
    height: '0.72em',
    position: 'relative',
    top: '0.0315em',
    left: '-0.027em'
  },
  category: {
  }
}, { name: 'HeroTitle' })

export default Title
