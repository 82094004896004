import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { span } from '../../style/span'
import theme from '../../style/theme'
import { vw } from '../../style/vw'
import BrandElements from '../BrandElements'

const BrandElementsBlock = ({ node }) => {
  const classes = useStyles()
  return (
    <div className={cn(classes.container, 'brand-element')}>
      <BrandElements className={classes.shapes} element={node.element} />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    padding: [vw(12), 0],
    width: span(6),
    [theme.breakpoints.up('md')]: {
      padding: [vw(40, 'desktop'), 0],
      width: span(4, 'md')
    }
  }
}, { name: 'BrandElementsBlock' })

export default BrandElementsBlock
