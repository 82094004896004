import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import gsap from 'gsap'
import largeScrollHint from '../animations/largeScrollHint.json'
import smallScrollHint from '../animations/smallScrollHint.json'
import useLottieAnimation from '../hooks/useLottieAnimation'
import { useInView } from 'react-intersection-observer'
import composeRefs from '../helpers/composeRefs'
import getViewportSize from '../helpers/getViewportSize'
import smoothScrollTo from '../helpers/smoothScrollTo'
import theme from '../style/theme'

function useScrollHintAnimation (animationData, inView) {
  const tweenRef = useRef()
  const targetRef = useLottieAnimation(tweenRef, animationData)

  useEffect(() => {
    const timeline = tweenRef.current
    if (timeline.labels && timeline.labels['loop-start'] && timeline.labels['loop-end']) {
      timeline.call(() => {
        timeline.play('loop-start')
      }, null, 'loop-end')
    }
  }, [])

  useEffect(() => {
    if (tweenRef.current) {
      tweenRef.current.reversed(!inView).resume()
    }
  }, [inView])

  return targetRef
}

function useDelayedValue (delay, value) {
  const [delayedValue, setDelayedValue] = useState(null)

  useEffect(() => {
    if (delay > 0) {
      const delayedCall = gsap.delayedCall(delay, () => {
        setDelayedValue(value)
      })
      return () => {
        delayedCall.kill()
      }
    } else {
      setDelayedValue(value)
    }
  }, [delay, value])

  return delayedValue
}

const ScrollHintShapes = ({ className, animationData, delay, ...rest }) => {
  const [inViewRef, inView] = useInView({ threshold: 1 })
  const delayedInView = useDelayedValue(inView ? delay : 0, inView)
  const animationRef = useScrollHintAnimation(animationData, delayedInView)
  const ref = composeRefs(inViewRef, animationRef)

  return (
    <div className={className} ref={ref} {...rest} />
  )
}

const ScrollHint = ({ className, variation }) => {
  const classes = useStyles()
  const animationData = variation === 'large'
    ? largeScrollHint
    : smallScrollHint
  const onClick = useCallback(() => {
    const { width, height } = getViewportSize()
    const y = height * (
      width >= theme.breakpoints.width('md')
        ? 1
        : 1.5
    )
    smoothScrollTo(y)
  })
  return (
    <ScrollHintShapes
      className={cn(classes.scrollHint, className)}
      animationData={animationData}
      delay={0.6}
      onClick={onClick}
    />
  )
}

const useStyles = createUseStyles({
  scrollHint: {
    cursor: 'pointer'
  }
}, { name: 'ScrollHint' })

export default ScrollHint
