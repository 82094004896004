import React, { useCallback, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { vw } from '../../style/vw'
import { isMenuOpen } from '../../redux/selectors'
import { toggleMenu } from '../../redux/actions'
import hamburgerData from '../../animations/hamburger.json'
import theme from '../../style/theme'
import useReversingLottieAnimation from '../../hooks/useReversingLottieAnimation'

const Hamburger = forwardRef(({ className, children, id }, ref) => {
  const classes = useStyles()
  const open = useSelector(isMenuOpen)
  const dispatch = useDispatch()
  const iconRef = useReversingLottieAnimation(hamburgerData, open)

  const handleMenuClick = useCallback(() => {
    dispatch(toggleMenu())
  }, [])

  return (
    <button ref={ref} className={cn(classes.button, className)} onClick={handleMenuClick} id={id} aria-label='Menu'>
      <span className={classes.icon} ref={iconRef} />
    </button>
  )
})

const useStyles = createUseStyles({
  button: {
    color: 'currentColor',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    width: vw(45),
    height: vw(45),
    minHeight: 42,
    minWidth: 42,
    [theme.breakpoints.up('md')]: {
      color: theme.colors.white, // its is always white on desktop
      width: vw(45, 'desktop'),
      height: vw(45, 'desktop')
    }
  },
  icon: {
    width: vw(25),
    height: vw(25),
    display: 'block',
    margin: ['0.1em', 'auto', 0],
    '& path': {
      fill: 'currentColor'
    },
    [theme.breakpoints.up('md')]: {
      width: vw(25, 'desktop'),
      height: vw(25, 'desktop')
    }
  }
}, { name: 'Hamburger' })

export default Hamburger
