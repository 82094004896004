import mapValues from 'lodash/mapValues'
import homeShape1 from './homeShape1.json'
import homeShape2 from './homeShape2.json'
import homeShape3 from './homeShape3.json'
import init1 from './init1.json'
import init2 from './init2.json'
import init3 from './init3.json'

const animations = {
  homeShape1,
  homeShape2,
  homeShape3,
  init1,
  init2,
  init3
}

export const aspectRatios = mapValues(animations, ({ w, h }) => w / h)

export default animations
