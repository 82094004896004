import get from 'lodash/get'
import { resolveLink } from '../../helpers/resolveLink'
import React from 'react'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import { createUseStyles } from 'react-jss'

const LogoLink = ({ link, logo }) => {
  const lnk = get(link, [0])
  const aspect = get(logo, ['aspect'], 1)
  const classes = useStyles({ aspect })
  return (
    <Link className={classes.link} link={resolveLink(lnk)} nonLinkTag='span'>
      <ResponsiveImage className={classes.logo} {...logo} />
    </Link>
  )
}

const height = 64
const useStyles = createUseStyles({
  link: {
    display: 'inline-block',
    marginBottom: '1.5em'
  },
  logo: {
    display: 'block',
    width: ({ aspect }) => aspect > 1 ? height * aspect : height,
    height: ({ aspect }) => aspect > 1 ? height : height / aspect
  }
}, { name: 'LogoLink' })

export default LogoLink
