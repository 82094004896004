import React, { forwardRef, useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import { useSelector } from 'react-redux'
import { getCookiesBannerContent } from '../redux/selectors/content'
import RichText from './RichText'
import { vw } from '../style/vw'
import { span } from '../style/span'
import Link from './Link'
import { resolveLink } from '../helpers/resolveLink'
import cn from 'classnames'
import { quart, expo } from '../style/eases'

const useStyles = createUseStyles({
  wrapper: {
    position: 'fixed',
    zIndex: theme.zIndex.nav,
    top: '100%',
    right: span(1),
    width: span(12),
    background: theme.colors.secondary,
    color: theme.colors.white,
    fontSize: vw(13),
    textAlign: 'center',
    borderRadius: vw(50),
    padding: [vw(10), vw(20)],
    visibility: 'hidden',
    transition: `visibility 1s, transform 1s ${quart.inOut}`,
    [theme.breakpoints.up('md')]: {
      right: span(0.5, 'md'),
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
      fontSize: vw(13, 'desktop'),
      borderRadius: vw(25, 'desktop'),
      padding: [vw(20, 'desktop'), vw(30, 'desktop'), vw(18, 'desktop')]
    },
    '&.is-active': {
      visibility: 'inherit',
      'transition-timing-function': expo.out,
      'transition-delay': '0.45s',
      transform: 'translate3d(0,-116%,0)',
      [theme.breakpoints.up('md')]: {
        transform: 'translate3d(0,-160%,0)'
      }
    }
  },
  text: {
    '& p': {
      display: 'inline'
    },
    '& em': {
      textTransform: 'none',
      fontStyle: 'normal',
      fontSize: '1.1em',
      letterSpacing: 1
    }
  },
  link: {
    color: theme.colors.white,
    marginLeft: '0.5em',
    '&:hover, &:visited': {
      color: theme.colors.white
    }

  },
  button: {
    userSelect: 'none',
    cursor: 'pointer',
    appearance: 'none',
    outline: 'none',
    border: 'none',
    background: 'none',
    color: theme.colors.white,
    textTransform: 'uppercase',
    lineHeight: 1,
    textDecoration: 'underline',
    padding: ['0.5em'],
    fontSize: vw(14),
    display: 'block',
    margin: [vw(4), 'auto', 0],
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      margin: [0, 0, 0, vw(50, 'desktop')],
      fontSize: vw(10, 'desktop')
    }

  }
}, { name: 'CookiesBanner' })

const CookiesBanner = forwardRef((props, ref) => {
  const classes = useStyles()
  const [isActive, setIsActive] = useState(false)

  const handleAccept = () => {
    setIsActive(false)
    window.localStorage.setItem('cookiesAccepted', true)
  }

  useEffect(() => {
    setIsActive(!window.localStorage.getItem('cookiesAccepted'))
  }, [])

  const {
    copy,
    terms_link: termsLink,
    cta_label: ctaLabel
  } = useSelector(getCookiesBannerContent)

  return (
    <div className={cn(classes.wrapper, isActive && 'is-active')}>
      <RichText className={classes.text} content={copy.text} tag='span'>
        {termsLink && <Link className={classes.link} link={resolveLink(termsLink[0])} />}
      </RichText>
      <button className={classes.button} onClick={handleAccept}>{ctaLabel}</button>
    </div>
  )
})

export default CookiesBanner
