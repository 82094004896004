import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import RichText from '../RichText'
import theme from '../../style/theme'
import { sliceMarginStyles, span } from '../../style/span'
import { vw } from '../../style/vw'

const RichTextSlice = forwardRef(({ className, slice, tag = 'div', serializers }, ref) => {
  const { text, title, columns } = slice
  const classes = useStyles({ columns })

  return (
    <section className={cn(classes.content, className)}>
      {title && <h3 className={classes.title}>{title}</h3>}
      <RichText ref={ref} className={classes.richText} content={text} tag={tag} serializers={serializers} />
    </section>
  )
})

const useStyles = createUseStyles({
  content: {
    extend: [sliceMarginStyles],
    margin: [0, 'auto'],
    padding: [0, span(1)],
    [theme.breakpoints.up('md')]: {
      padding: [0, span(2, 'md')]
    }
  },
  title: {
    marginBottom: vw(32),
    fontSize: vw(34),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(56, 'desktop'),
      marginBottom: vw(48, 'desktop'),
      width: span(8, 'md')
    }
  },
  richText: {
    columnGap: ({ columns }) => columns === '3' ? span(2, 'md') : span(3, 'md'),
    [theme.breakpoints.up('md')]: {
      columnCount: ({ columns }) => columns
    }
  }
}, { name: 'RichTextSlice' })

export default RichTextSlice
