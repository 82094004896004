import map from 'lodash/map'
import get from 'lodash/get'
import React from 'react'
import { createUseStyles } from 'react-jss'
import LogoLink from './LogoLink'

const LogoLinks = ({ node }) => {
  const classes = useStyles()
  console.log(node)
  const links = get(node, ['links'])
  return (
    <div className={classes.logoLinks}>
      {map(links, (link, i) => (
        <LogoLink key={i} {...link} />
      ))}
    </div>
  )
}

const useStyles = createUseStyles({
  logoLinks: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: '1.5em'
    }
  }
}, { name: 'LogoLinks' })

export default LogoLinks
