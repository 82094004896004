import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import animations, { aspectRatios } from '../animations'
import InViewShapes from './InViewShapes'
import round from '../helpers/round'

const BrandElements = ({ className, element, ShapesComponent = InViewShapes, ...rest }) => {
  const aspect = aspectRatios[element] || 1
  const classes = useStyles({ aspect })
  const animationData = animations[element]
  if (!animationData) {
    return null
  }
  return (
    <ShapesComponent
      className={cn(className, classes.brandElements)}
      animationData={animationData}
      {...rest}
    />
  )
}

const useStyles = createUseStyles({
  brandElements: ({ aspect }) => ({
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: `${round(100 / aspect)}%`
    },
    '& > svg': {
      position: 'absolute',
      left: 0,
      top: 0
    }
  })
}, { name: 'BrandElementsBlock' })

export default BrandElements
