import React, { useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import first from 'lodash/first'
import { sliceMarginStyles, span } from '../../../style/span'
import Ticker from './Ticker'
import ResponsiveImage from '../../ResponsiveImage'
import theme from '../../../style/theme'
import { vw } from '../../../style/vw'
import Link from '../../Link'
import { resolveLink } from '../../../helpers/resolveLink'
import Flickity from 'react-flickity-component'

const flickityOptions = {
  cellAlign: 'center',
  pageDots: false,
  contain: true,
  wrapAround: true,
  selectedAttraction: 0.0075,
  friction: 0.15
}

export const onSlideWipeAnimation = (flickity) => (event, progress) => {
  if (!flickity) {
    return
  }

  var slidesViewport = flickity.slidesWidth -
    ((window.innerWidth - first(flickity.slides).outerWidth) * (Math.abs(flickity.cellAlign - 0.5) * 2))

  flickity.slides.forEach((slide, i) => {
    const { element } = first(slide.cells)
    const flickityX = flickity.x
    var x = slide.target + flickityX

    const contentElement = element.children[1]
    const imageElement = element.children[0].children[0]

    if (i === 0) {
      x = Math.abs(flickityX) > slidesViewport
        ? (flickity.slidesWidth + flickityX + flickity.slides[flickity.slides.length - 1].outerWidth + slide.target)
        : (slide.target + flickityX)
    } else if (i === flickity.slides.length - 1) {
      x = Math.abs(flickityX) + flickity.slides[i].outerWidth < slidesViewport
        ? (slide.target - flickity.slidesWidth + flickityX - flickity.slides[i].outerWidth)
        : (slide.target + flickityX)
    }

    contentElement.style.transform = `translateX(${-x * 0.90}px)`
    const opacityValue = 1 - (Math.abs(x) / (slide.outerWidth / 2))
    contentElement.style.opacity = opacityValue
    imageElement.style.transform = `translateX(${-x * 0.85}px)`
  })
}

const AmbitionsCarouselItem = ({ title, copy, image, link }) => {
  const classes = useItemStyles()
  const ref = useRef()
  const imageRef = useRef()

  const lnk = resolveLink(get(link, [0]))
  return (
    <div className={classes.item} ref={ref}>
      <Link link={lnk} className={classes.imageLink} ref={imageRef} nonLinkTag='div'>
        <ResponsiveImage {...image} className={classes.image} />
      </Link>
      <div className={classes.contentContainer}>
        <Link className={classes.title} link={lnk} nonLinkTag='div'>
          <h2>{title}</h2>
        </Link>
        <p className={classes.copy}>{copy}</p>
      </div>
    </div>
  )
}

const AmbitionsCarousel = ({ className, slice: { ticker, items } }) => {
  const classes = useStyles()
  const flickityRef = useRef()

  const setFlickityRef = useCallback((flickity) => {
    flickityRef.current = flickity
    flickityRef.current.element.style.display = 'block'
    flickity.on('dragStart', () => { document.ontouchmove = e => e.preventDefault() })
    flickity.on('dragEnd', () => { document.ontouchmove = () => true })
    flickity.on('scroll', onSlideWipeAnimation(flickity))
  }, [])

  const onNext = useCallback(() => {
    if (flickityRef.current) {
      flickityRef.current.next()
    }
  }, [])

  const onPrevious = useCallback(() => {
    if (flickityRef.current) {
      flickityRef.current.previous()
    }
  }, [])

  return (
    <section className={cn(classes.section, className)}>
      <Ticker titles={ticker} />
      <div className={classes.carouselWrapper}>
        <Flickity
          options={flickityOptions}
          disableImagesLoaded
          reloadOnUpdate
          static
          className={classes.carousel}
          flickityRef={setFlickityRef}
        >
          {items && items.map((item, i) => (
            <AmbitionsCarouselItem key={item.title} {...item} />
          ))}
        </Flickity>
        <button className={classes.previousButton} onClick={onPrevious}>Prev</button>
        <button className={classes.nextButton} onClick={onNext}>Next</button>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    extend: [sliceMarginStyles]
  },
  carouselWrapper: {
    position: 'relative',
    marginBottom: vw(-18),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(-30, 'desktop')
    }
  },
  button: {
    position: 'absolute',
    zIndex: 99,
    textTransform: 'uppercase',
    padding: [vw(8), 0],
    top: span(7.2), // (13/9 (inverted image aspect) * 10 (image width) ? 2 (center of the image))
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(18, 'desktop'),
      padding: [vw(8, 'desktop'), 0],
      top: span(6.5, 'md') // (13/9 (inverted image aspect) * 9 (image width) ? 2 (center of the image))
    },
    '&:before, &:after': {
      transition: 'transform 0.5s',
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: 'currentColor'
    },
    '&:before': {
      transform: 'translateX(-200%)'
    },
    '&:after': {
      transform: 'translateX(0%)'
    },
    '&:hover, &.selected': {
      '&:before': {
        transform: 'translateX(0%)'
      },
      '&:after': {
        transform: 'translateX(200%)'
      }
    }
  },
  previousButton: {
    extend: 'button',
    transform: 'translate(-50%, -50%)',
    left: span(2),
    [theme.breakpoints.up('md')]: {
      transform: 'translate(0, 0)',
      left: span(5, 'md')
    }
  },
  nextButton: {
    extend: 'button',
    transform: 'translate(50%, -50%)',
    right: span(2),
    [theme.breakpoints.up('md')]: {
      transform: 'translate(0, 0)',
      right: span(5, 'md')
    }
  },
  carousel: {
    outline: 'none',
    whiteSpace: 'nowrap',
    display: 'flex',
    margin: [vw(-12), 'auto', 0],
    width: span(12),
    color: '#111', // Not sure why but this is the only component that has this color text
    [theme.breakpoints.up('md')]: {
      width: span(9, 'md'),
      margin: [vw(-24, 'desktop'), 'auto', 0]
    },
    '& > *': {
      flexShrink: 0
    }
  }
}, { name: 'AmbitionsCarousel' })

const useItemStyles = createUseStyles({
  item: {
    flexShrink: 0,
    whiteSpace: 'normal',
    width: span(12),
    margin: [0, '35%'],
    [theme.breakpoints.up('md')]: {
      margin: [0, '35%'],
      width: span(9, 'md')
    }
  },
  imageLink: {
    display: 'block',
    overflow: 'hidden'
  },
  image: {
    userSelect: 'none',
    outline: 'none',
    overflow: 'visible',
    margin: [0, span(1)],
    width: `calc(100% - ${span(2)})`,
    [theme.breakpoints.up('md')]: {
      width: '100%',
      margin: 0
    }
  },
  contentContainer: {
    marginTop: vw(18),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(30, 'desktop')
    }
  },
  title: {
    display: 'block',
    position: 'relative',
    width: span(8),
    textAlign: 'center',
    margin: [0, 'auto', vw(20)],
    textDecoration: 'none',
    color: 'currentColor',
    '& h2': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      margin: [0, 'auto', vw(20, 'desktop')],
      width: span(5, 'md')
    }
  },
  copy: {
    textAlign: 'center',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      margin: [0, span(1, 'md')]
    }
  }
}, { name: 'AmbitionsCarousel' })

export default AmbitionsCarousel
