import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getPageId,
  isContentBusy,
  getPageSlices
} from '../redux/selectors'
import Footer from './Footer'
import { SwitchTransition, Transition } from 'react-transition-group'
import { updateScroll } from 'redux-first-router'
import { beginRouteExitTransitionCreator, routeExitTransitionEndedCreator } from '../redux/actions'

import SmoothScroll from './SmoothScroll'
import CookiesBanner from './CookiesBanner'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'

import gsap from 'gsap'
import color from 'color'
import Nav from './Nav'
import Slices from './Slices'

const usePageTransition = () => {
  const [transitionState, setTransitionState] = useState()
  const transitionCompleteRef = useRef()
  const busy = useSelector(isContentBusy)
  const curtainRef = useRef()
  const transitionRef = useRef()
  const dispatch = useDispatch()
  const timeline = useRef()

  useEffect(() => {
    // timeline.current is not null then we are already transitioning out
    if (busy && !timeline.current) {
      dispatch(beginRouteExitTransitionCreator())
      setTransitionState('begin-out')
      timeline.current = gsap.timeline()
      const tl = timeline.current
      tl.fromTo(curtainRef.current, { x: '100%' }, {
        x: '0%',
        duration: 0.8,
        ease: 'quart.in',
        onComplete: () => {
          timeline.current = null
          setTransitionState('end-out')
        }
      }, 0)
      tl.to(transitionRef.current, {
        x: '-25%',
        duration: 0.8,
        ease: 'quart.in'
      }, 0)
    }
  }, [busy])

  useEffect(() => {
    if (!busy && transitionState === 'end-out') {
      dispatch(routeExitTransitionEndedCreator())
      updateScroll()
      if (transitionCompleteRef.current) {
        transitionCompleteRef.current()
        transitionCompleteRef.current = null
      }
      setTransitionState('begin-in')
      const tl = gsap.timeline({
        onComplete: () => {
          setTransitionState('end-in')
        },
        delay: 0.1
      })
      tl.to(curtainRef.current, {
        x: '-100%',
        ease: 'expo.out',
        duration: 1
      }, 0)
      tl.fromTo(transitionRef.current, { x: '25%' }, {
        x: '0%',
        ease: 'expo.out',
        duration: 1,
        clearProps: 'transform'
      }, 0)
    }
  }, [busy, transitionState])

  const endTransitionCallback = useCallback((node, done) => {
    if (transitionState === 'end-out') {
      timeline.current = null
      done()
    } else {
      transitionCompleteRef.current = done
    }
  }, [transitionState, busy])

  const onEnter = useCallback(node => {
    gsap.fromTo(transitionRef.current, { x: '25%' }, {
      x: '0%',
      ease: 'expo.out',
      duration: 1,
      clearProps: 'transform'
    }, 0)
  }, [])

  return { curtainRef, transitionRef, endTransitionCallback, onEnter }
}

const Page = ({ className }) => {
  const classes = useStyles()
  const slices = useSelector(getPageSlices)
  const id = useSelector(getPageId)

  const { curtainRef, transitionRef, endTransitionCallback, onEnter } = usePageTransition()

  return (
    <>
      <div ref={curtainRef} className={classes.curtain} />
      <Nav />
      <CookiesBanner />
      <SwitchTransition>
        <Transition
          key={id}
          addEndListener={endTransitionCallback}
          onEnter={onEnter}
        >
          <main className={className}>
            <SmoothScroll>
              <div ref={transitionRef} className={classes.transitionWrapper} id={id}>
                <Slices slices={slices} />
              </div>
              <Footer />
            </SmoothScroll>
          </main>
        </Transition>
      </SwitchTransition>
    </>
  )
}

const useStyles = createUseStyles({
  curtain: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: color(theme.colors.primary).lighten(0.1).hex(),
    transform: 'translateX(-100%)',
    zIndex: theme.zIndex.pageLoadingBar
  },
  transitionWrapper: {
    position: 'relative'
  }
})

export default Page
