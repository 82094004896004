import {
  NAV_MOUNT,
  NAV_UNMOUNT,
  BREAKPOINT_CHANGED,
  TOGGLE_MENU,
  ROUTE_EXIT_TRANSITION_ENDED,
  setNavColors
} from '../actions'
import gsap from 'gsap'
import detectIt from 'detect-it'
import forEach from 'lodash/forEach'
import SmoothScroll from '../../components/SmoothScroll'
import { values, current } from '../../style/breakpoints'
import { spanPx } from '../../style/span'
import {
  isCurrentBreakpointMobile,
  isMenuOpen,
  getHeroBackgroundColor
} from '../selectors'
import theme from '../../style/theme'
import SplitText from 'gsap/SplitText'
import { CustomWiggle } from 'gsap/CustomWiggle'
import { CustomEase } from 'gsap/CustomEase'
import { ExpoScaleEase } from 'gsap/EasePack'
import getViewportSize from '../../helpers/getViewportSize'

gsap.registerPlugin(ExpoScaleEase, CustomEase, CustomWiggle)

var navElement
var hamburgerElement
var circleElement
var menuElement
var hamburgerVisible = false
var menuTimeLine
var hamburgerTimeLine

export const isMobile = () => values[current()] < values.md

const animationHeaderScroll = () => {
  const translate = `translate3d(0,${-SmoothScroll.y}px,0)`
  navElement.style.transform = translate
}

const getHamburgerOffset = (element) => {
  const bb = element.getBoundingClientRect()
  return -(spanPx(0.8, 'md') + bb.height + 50)
}

const animateHamburgerVisibility = () => {
  const show = SmoothScroll.resolveY() > (isMobile() ? 100 : 200)
  const triggerShow = !hamburgerVisible && show
  const triggerHide = hamburgerVisible && !show

  if (triggerShow || triggerHide) {
    hamburgerVisible = triggerShow
    if (hamburgerTimeLine) hamburgerTimeLine.kill()
    hamburgerTimeLine = gsap.timeline()
    if (isMobile()) {
      if (triggerShow) {
        hamburgerTimeLine.set(hamburgerElement, { color: theme.colors.white })
        hamburgerTimeLine.to(circleElement, { scale: 1, duration: 0.25, ease: 'back.out(1.8)' })
      }
      if (triggerHide) {
        hamburgerTimeLine.set(hamburgerElement, { clearProps: 'color' })
        hamburgerTimeLine.to(circleElement, { scale: 0, duration: 0.25, ease: 'back.out(0.8)' })
      }
    } else {
      hamburgerTimeLine.set(circleElement, { scale: 1 })
      if (triggerShow) {
        hamburgerTimeLine.to([hamburgerElement, circleElement], { y: 0, duration: 0.8, ease: 'elastic.out(0.5, 0.4)' })
      }
      if (triggerHide) {
        hamburgerTimeLine.to([hamburgerElement, circleElement], { y: getHamburgerOffset(hamburgerElement), duration: 0.25, ease: 'sine.in' })
      }
    }
  }
}

const animateMenu = (show) => {
  if (menuTimeLine) menuTimeLine.kill()
  menuTimeLine = gsap.timeline({ onComplete: () => { menuTimeLine = null } })
  const currentScale = Math.ceil(gsap.getProperty(circleElement, 'scale') || 1)
  if (show) {
    const { width, height } = getViewportSize()
    const radius = Math.sqrt(height * height + width * width)
    const scale = radius / 21
    menuTimeLine.to(hamburgerElement.children[0], { color: theme.colors.white, duration: 0.25 })
    menuTimeLine.to(circleElement, { scale, duration: 0.6, ease: `expoScale(${currentScale}, ${Math.ceil(scale)}, sine.out)` }, 0)
    menuTimeLine.to(menuElement, { autoAlpha: 1, duration: 0.5, clearProps: 'pointerEvents' })
    menuTimeLine.add(createLinkAnimations(0.6))
    menuTimeLine.fromTo(menuElement.querySelectorAll('ul')[1].children, { opacity: 0 }, { opacity: 1, duration: 1.6 }, '-=0.4')
  } else {
    menuTimeLine.to(menuElement, { autoAlpha: 0, duration: 0.25, pointerEvents: 'none' })
    menuTimeLine.set(hamburgerElement.children[0], { clearProps: 'color' })

    if (isMobile() && SmoothScroll.resolveY() < 200) {
      menuTimeLine.to(circleElement, { scale: 0, duration: 0.5, ease: `expoScale(${currentScale}, 0.1)` }, '-=0.15')
    } else {
      menuTimeLine.to(circleElement, { scale: 1, duration: 0.4, ease: `expoScale(${currentScale}, 1)` }, '-=0.15')
      menuTimeLine.to(circleElement, { scale: 0.8, duration: 0.4, ease: 'wiggle({ type:easeOut, wiggles:3 })' })
    }
  }
}

const createLinkAnimations = (delay) => {
  const nav = menuElement.querySelectorAll('nav')[0]
  const links = nav.querySelectorAll('a')
  const tl = gsap.timeline()
  forEach(links, (link, i) => tl.add(createSpitWordTimeline(link, (i / 10)), delay))
}

const createSpitWordTimeline = (el, delay) => {
  var timeline = gsap.timeline()

  const locals = {}
  locals.split = new SplitText(el, { type: 'words,lines' })
  timeline.set(locals.split.lines, { overflow: 'hidden' })
  timeline.set(locals.split.words, { yPercent: 100 })
  locals.el = el

  timeline.to(
    locals.split.words,
    { ease: 'expo.out', yPercent: 0, stagger: 0.06, duration: 1.2, visibility: 'visible', delay }
  )

  return timeline
}

export default store => next => action => {
  const result = next(action)

  switch (action.type) {
    case NAV_MOUNT: {
      const { navId, hamburgerId, menuId, circleId } = action.payload
      navElement = document.getElementById(navId)
      if (navElement) {
        if (detectIt.primaryInput !== 'touch') {
          gsap.set(navElement, { position: 'fixed' })
          gsap.ticker.add(animationHeaderScroll)
        } else {
          gsap.set(SmoothScroll, { y: 0, position: 'absolute' })
        }
      }

      circleElement = document.getElementById(circleId)
      hamburgerElement = document.getElementById(hamburgerId)
      if (hamburgerElement) {
        gsap.set([hamburgerElement, circleElement], { opacity: 1 })
        if (!isMobile()) {
          gsap.set([hamburgerElement, circleElement], { y: getHamburgerOffset(hamburgerElement) })
        } else {
          gsap.set(circleElement, { scale: 0 })
        }
        gsap.ticker.add(animateHamburgerVisibility)

        const onHamburgerHover = (over) => {
          const menuOpen = isMenuOpen(store.getState())
          if (menuOpen) {
            gsap.to(hamburgerElement, { rotate: over ? 90 : 0, ease: 'expo.out', duration: 0.4 })
          } else {
            if (!menuTimeLine) {
              gsap.to(circleElement, { scale: over ? 1.2 : 1, ease: 'expo.out', duration: 0.4 })
            }
          }
        }

        hamburgerElement.addEventListener('mouseenter', () => { onHamburgerHover(true) })
        hamburgerElement.addEventListener('mouseleave', () => { onHamburgerHover(false) })
        hamburgerElement.addEventListener('click', () => { onHamburgerHover(false) })
      }

      menuElement = document.getElementById(menuId)

      break
    }
    case NAV_UNMOUNT: {
      gsap.ticker.remove(animationHeaderScroll)
      gsap.ticker.remove(animateHamburgerVisibility)
      break
    }
    case BREAKPOINT_CHANGED: {
      if (hamburgerElement) {
        const menuOpen = isMenuOpen(store.getState())
        if (!isCurrentBreakpointMobile(store.getState())) {
          if (!menuOpen) {
            gsap.set(circleElement, { scale: 1 })
          }
          if (!hamburgerVisible) {
            gsap.set([hamburgerElement, circleElement], { y: getHamburgerOffset(hamburgerElement) })
          }
        } else {
          gsap.set([hamburgerElement, circleElement], { y: 0 })
          if (!hamburgerVisible && !menuOpen) {
            gsap.set(circleElement, { scale: 0 })
          }
        }
      }
      break
    }
    case ROUTE_EXIT_TRANSITION_ENDED: {
      gsap.set(circleElement, { scale: window.scrollY === 0 ? 0 : 1 })
      gsap.set(menuElement, { autoAlpha: 0, pointerEvents: 'none' })
      gsap.set(hamburgerElement.children[0], { clearProps: 'color' })

      const backgroundColor = getHeroBackgroundColor(store.getState())
      store.dispatch(setNavColors(backgroundColor))

      break
    }
    case TOGGLE_MENU: {
      animateMenu(isMenuOpen(store.getState()))
      break
    }
  }

  return result
}
