import React, { forwardRef, useCallback, useEffect, useRef, useImperativeHandle } from 'react'
import { createUseStyles } from 'react-jss'
import range from 'lodash/range'
import theme from '../../../style/theme'
import { vw } from '../../../style/vw'
import gsap from 'gsap'

const Progress = forwardRef(({ paused, steps, seekStep }, ref) => {
  const classes = useStyles()
  const progressRef = useRef()
  const progressTimelineRef = useRef()
  const currentStep = useRef()

  const cancelProgressTimeline = useCallback(() => {
    if (progressTimelineRef.current) {
      progressTimelineRef.current.pause()
      gsap.to(progressRef.current, { opacity: 0, duration: 2, x: `+=${(2 / (steps.length * 5)) * 100}%` })
      progressTimelineRef.current = null
    }
  }, [])

  useImperativeHandle(ref, () => ({
    cancel: cancelProgressTimeline
  }), [cancelProgressTimeline])

  useEffect(() => {
    if (progressRef.current) {
      progressTimelineRef.current = gsap.timeline()
      const tl = progressTimelineRef.current
      tl.to(progressRef.current, {
        x: '0%',
        duration: steps.length * 5,
        ease: 'none',
        onUpdate: (progress) => {
          const stepIndex = Math.floor(gsap.utils.interpolate(range(steps.length), tl.progress()))
          if (currentStep.current !== stepIndex) {
            currentStep.current = stepIndex
            seekStep(stepIndex)
          }
        },
        onComplete: () => {
          cancelProgressTimeline()
        }
      })
      return () => {
        tl.kill()
      }
    }
  }, [seekStep])

  useEffect(() => {
    if (progressTimelineRef.current) {
      if (paused) {
        progressTimelineRef.current.pause()
      } else {
        progressTimelineRef.current.play()
      }
    }
  }, [progressTimelineRef.current, paused])

  return (
    <div className={classes.progressContainer}>
      <div ref={progressRef} className={classes.progress} />
    </div>
  )
})

const useStyles = createUseStyles({
  progressContainer: {
    position: 'absolute',
    left: 22,
    right: 22,
    bottom: 22,
    overflow: 'hidden',
    height: 2,
    [theme.breakpoints.up('lg')]: {
      bottom: vw(22, 'desktop')
    }
  },
  progress: {
    position: 'absolute',
    left: 0,
    width: '100%',
    transform: 'translate(-100%, 0)',
    borderBottom: `2px solid ${theme.colors.white}`
  }
}, { name: 'Progress' })

export default Progress
