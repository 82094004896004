import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import Link from './Link'
import theme from '../style/theme'
import cn from 'classnames'
import { vw } from '../style/vw'
import { expo } from '../style/eases'
import { isExternalUrl } from '../helpers/url'
import { ReactComponent as ExternalLinkIcon, aspectRatio as externalLinkIconAspect } from '../images/externalLink.svg'
import round from '../helpers/round'
import get from 'lodash/get'
import color from 'color'

const needsExternalLinkIcon = url => (
  url &&
  isExternalUrl(url) &&
  !/^(?:mailto|tel):/.test(url)
)

const Button = forwardRef(({ children, to, link, transparent, black, className, ...rest }, ref) => {
  const classes = useStyles()
  const Tag = to || link ? Link : 'button'
  const external = needsExternalLinkIcon(to || (link && link.url))
  const variationClass = transparent ? classes.transparent : black ? classes.solidBlack : classes.solid
  return (
    <Tag className={cn(classes.button, variationClass, className)} to={to} link={link} ref={ref} {...rest}>
      {children}
      {external && (
        <>
          {!children && get(link, ['text'], rest.defaultText)}
          <ExternalLinkIcon className={classes.externalLinkIcon} />
        </>
      )}
    </Tag>
  )
})

const useStyles = createUseStyles({
  button: {
    color: theme.colors.button,
    lineHeight: 1,
    fontWeight: 'inherit',
    border: `1px solid ${theme.colors.button}`,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    fontSize: 18,
    padding: [17, 30, 17, 30],
    borderRadius: 27,
    flexGrow: 0,
    flexShrink: 0,
    transition: `background 0.5s ${expo.out}, color 0.5s ${expo.out}`,
    [theme.breakpoints.up('lg')]: {
      padding: [vw(17, 'desktop'), vw(30, 'desktop'), vw(17, 'desktop'), vw(30, 'desktop')],
      borderRadius: vw(27, 'desktop'),
      fontSize: vw(18, 'desktop')
    },
    '&.disable': {
      opacity: 0.8,
      pointerEvents: 'none'
    }
  },
  transparent: {
    background: 'transparent',
    borderColor: theme.colors.white,
    color: theme.colors.white,
    '&:hover': {
      borderColor: theme.colors.white
    }
  },
  solid: {
    borderColor: theme.colors.button,
    background: theme.colors.button,
    color: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        color: theme.colors.button,
        background: color(theme.colors.button).alpha(0).rgb().string()
      }
    }
  },
  solidBlack: {
    borderColor: theme.colors.black,
    background: theme.colors.black,
    color: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        color: theme.colors.black,
        background: color(theme.colors.black).alpha(0).rgb().string()
      }
    }
  },
  externalLinkIcon: {
    display: 'inline-block',
    width: `${round(16 / 18)}em`,
    height: `${round((16 / 18) / externalLinkIconAspect)}em`,
    marginLeft: `${round(14 / 18)}em`
  }
}, { name: 'Button' })

export default Button
