import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { getMenuItems, isMenuOpen, getHeader } from '../../redux/selectors'
import { resolveLink } from '../../helpers/resolveLink'
import Link from '../Link'
import theme from '../../style/theme'
import outlineText from '../../style/outlineText'
import useBodyScrollLock from '../../hooks/useBodyScrollLock'
import { span } from '../../style/span'
import { vw } from '../../style/vw'
import LinkGroups from '../LinkGroups'
import { ReactComponent as Logo, aspectRatio } from '../../images/logo.svg'
import Contours from '../Contours'
import gsap from 'gsap'

const Menu = ({ id }) => {
  const classes = useStyles()
  const linkGroupClasses = useLinkGroupsStyles()
  const menuItems = useSelector(getMenuItems)
  const { homeTitle, links, socialTitle } = useSelector(getHeader)
  const open = useSelector(isMenuOpen)
  const containerRef = useBodyScrollLock(open)
  const contoursRef = useRef()
  const [showContours, setShowContours] = useState(false)

  useEffect(() => {
    if (open) {
      // We need to delay the mounting of the contours so that the if they are visible in background page then they do not flicker
      var timeout = setTimeout(() => setShowContours(true), 600)
      return () => {
        clearTimeout(timeout)
      }
    } else {
      if (contoursRef.current) {
        // I am doing this because if we just unmount the contours component we see a flicker
        gsap.to(contoursRef.current, { autoAlpha: 0, duration: 0.1, onComplete: () => setShowContours(false) })
      }
    }
  }, [open])

  return (
    <div className={classes.dialog} id={id}>
      {showContours && (
        <div ref={contoursRef} className={classes.contourContainer}>
          <Contours animate backgroundColor={theme.colors.secondary} fixed />
        </div>
      )}
      <div className={classes.container} ref={containerRef}>
        <header className={classes.header}><Logo className={classes.logo} /></header>
        <nav className={classes.nav}>
          {menuItems && (
            <ul className={classes.list}>
              <li>
                <Link className={cn(classes.link)} to='/'>{homeTitle}</Link>
              </li>
              {menuItems.map(item => (
                <li key={item.text}>
                  <Link className={classes.link} link={resolveLink(item)} />
                </li>
              ))}
            </ul>
          )}
        </nav>
        <div className={classes.contactContainer}>
          <LinkGroups
            className={linkGroupClasses.linkGroups}
            classNames={linkGroupClasses}
            links={links}
            socialTitle={socialTitle}
            socialLinksFirst
          />
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  dialog: {
    color: theme.colors.white,
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    visibility: 'hidden',
    backgroundColor: theme.colors.secondary
  },
  contourContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none'
  },
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    overscrollBehaviour: 'contain',
    '& > *': {
      flexShrink: 0
    },
    padding: [span(3), span(1), span(1)],
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      padding: span(2, 'md')
    }
  },
  header: {
    position: 'fixed',
    height: vw(45),
    top: span(1),
    left: span(1),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      top: span(0.65, 'md'),
      left: span(0.65, 'md'),
      height: vw(45, 'desktop')
    }
  },
  logo: {
    width: vw(22 * aspectRatio),
    height: vw(22),
    [theme.breakpoints.up('md')]: {
      width: vw(22 * aspectRatio, 'desktop'),
      height: vw(22, 'desktop')
    }
  },
  nav: {
    width: '100%',
    display: 'block',
    marginBottom: span(2),
    [theme.breakpoints.up('md')]: {
      width: span(12, 'md'),
      marginRight: span(2, 'md'),
      marginBottom: 0
    }
  },
  list: {
    padding: 0,
    margin: 0,
    listStyle: 'none'
  },
  link: {
    fontFamily: theme.fonts.headings,
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: vw(56),
    color: theme.colors.white,
    textDecoration: 'none',
    display: 'inline-block',
    lineHeight: 1.3,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(102, 'desktop')
    },
    ...outlineText(theme.colors.white),
    '&:hover': {
      color: 'transparent'
    }
  },
  contactContainer: {
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: span(4, 'md')
    }
  }
}, { name: 'Menu' })

const useLinkGroupsStyles = createUseStyles({
  linkGroups: {
    fontSize: 16,
    [theme.breakpoints.up('lg')]: {
      fontSize: vw(16, 'desktop')
    }
  },
  linkGroup: {
    width: '100%',
    marginBottom: span(1.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: span(1, 'md')
    },
    '&:nth-child(n)': {
      order: 'initial'
    },
    '&:last-child': {
      marginBottom: 0
    }
  },
  linkGroupTitle: {
    fontFamily: theme.fonts.body,
    fontWeight: 400,
    fontSize: 18,
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: vw(18, 'desktop')
    }
  },
  link: {
    '&:before, &:after': {
      backgroundColor: 'currentColor'
    }
  },
  textLink: {
    padding: ['0.11em', 0]
  }
}, { name: 'MenuLinkGroups' })

export default Menu
