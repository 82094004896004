import React from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import cn from 'classnames'
import theme from '../../style/theme'
import { sliceMarginStyles, span } from '../../style/span'
import SplitWordsAnimation from '../SplitWordsAnimation'
import TinyCategoryTitle from './TinyCategoryTitle'
import BrandElements from '../BrandElements'

const PullQuote = ({ slice }) => {
  const classes = useStyles()
  const { title, text } = slice
  const element = get(slice, ['decoration', 0, 'element'])

  return (
    <blockquote className={cn(classes.pullQuote, element && classes.withDecoration)}>
      {element && (
        <div className={classes.decoration}>
          <BrandElements className={classes.shape} element={element} />
        </div>
      )}
      <SplitWordsAnimation linesOnly>
        <div className={classes.text}>
          {title && (
            <TinyCategoryTitle>
              <h3 className={classes.title}>{title}</h3>
            </TinyCategoryTitle>
          )}
          {text}
        </div>
      </SplitWordsAnimation>
    </blockquote>
  )
}

const useStyles = createUseStyles({
  pullQuote: {
    extend: [sliceMarginStyles],
    margin: [0, 'auto'],
    width: span(12),
    [theme.breakpoints.up('md')]: {
      width: span(15, 'md'),
      paddingRight: span(1, 'md')
    }
  },
  withDecoration: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'flex-start',
      width: span(19, 'md'),
      paddingRight: 0
    }
  },
  title: {
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      height: '0.8em',
      minWidth: span(4, 'md'),
      '$withDecoration &': {
        minWidth: span(2, 'md')
      }
    }
  },
  text: {
    extend: [theme.global[theme.headingsSelector], theme.global.h2]
  },
  decoration: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: span(8, 'md')
    }
  },
  shape: {
    [theme.breakpoints.up('md')]: {
      width: span(4, 'md')
    }
  }
}, { name: 'PullQuote' })

export default PullQuote
